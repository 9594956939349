import React from "react";
import { makeStyles } from '@mui/styles';
//import Typical from 'react-typical';
import "./styles.css";
import { Box } from "@mui/material";
//import Button from '@mui/material/Button';
//import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  otro: {
    height: 'auto'
  },
  Dentro: {
    width: "100px", height: "100px",
    position: "relative", float: "left",
    backgroundColor: "#000000",
    margin: "0px 5px",
    color: "#ffffff",
    fontWeight: "bold"
  },
  column: {
    float: "left",
    width: "33.33%",
    padding: "5px"
  }
}))

function Ubicacion() {
  // Ubicacion 
  const classes = useStyles();

  //document.getElementById("HomeTopBar").style.display = "block";

  return (
    <div>
      <div className={classes.otro}>
        <div className="App">
          <header className="App-header">

            {/*<h4><code>Burros Y Tacos El Maik.</code></h4>*/}

            {/*<p style={{ fontFamily: 'monospace' }}>
              Aqui podras encontrar el Menu, Ubicacion, Contactos, dejar comentarios, etc.
  </p>*/}

            Ubicación

            <br />
            <br />

            <Box className="mapouter" width={"100%"} height={"50vh"}>
              <Box className="gmap_canvas" height={"100%"}>
                <iframe title="Burros Y Tacos El Maik" width="100%" height="100%" id="gmap_canvas" src="https://maps.google.com/maps?q=burros%20y%20tacos%20el%20maik&t=&z=17&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0">
                </iframe>

              </Box>
            </Box>

          </header>
        </div>
      </div>
    </div>
  );
}

export default Ubicacion;