// Archivos.js
import React, { useState, useEffect } from "react";
import FileUploader from "./FileUploader";
import FileManager from "./FileManager";
import AWS from "./aws-config";
import { /*Link,*/ useNavigate, useLocation } from 'react-router-dom';

const Archivos = ({ isMobileMode, isPCDrawerOpen }) => {
  const [fileList, setFileList] = useState([]);
  const navigate = useNavigate(); // Obtén la función navigate
  const location = useLocation();
  const isLoggedIn = location?.state?.fromTools ? true : false;

  const deleteFile = (fileName) => {
    const s3 = new AWS.S3();
    const params = {
      Bucket: "emmanueltc.db.tools",
      Key: fileName,
    };

    s3.deleteObject(params, (err, data) => {
      if (err) {
        console.error(err);
      } else {
        console.log("Archivo eliminado con éxito:", fileName);
        // Puedes realizar alguna acción adicional aquí, como actualizar la lista de archivos.
        // Actualiza la lista de archivos después de la eliminación
        updateFileList();
      }
    });
  };

  const updateFileList = () => {
    console.log("Realizando polling..."); // Agregar este registro
    const s3 = new AWS.S3();
    const params = {
      Bucket: "emmanueltc.db.tools",
    };

    s3.listObjectsV2(params, (err, data) => {
      if (err) {
        console.error(err);
      } else {
        const files = data.Contents.map((object) => object.Key);
        setFileList(files);
      }
    });
  };

  useEffect(() => {
    console.log('Archivos.js se ha montado correctamente.');
    console.log('isLoggedIn:', isLoggedIn);
    console.log('location.state:', location.state);

    // Verifica si el usuario intentó acceder directamente a través de la URL
    if (!isLoggedIn || !location.state || location.state.fromTools !== true) {
      console.log('Redirigiendo a /');
      navigate('/', { replace: true });
    } else {
      // Si viene desde Tools.js, actualiza la lista de archivos
      console.log('Actualizando lista de archivos');
      updateFileList();
    }
  }, [location, navigate, isLoggedIn]);


  /*const handleUpdateList = () => {
    // Simplemente refresca la lista llamando a la API de S3 nuevamente
    updateFileList();

    // Simplemente refresca la lista llamando a la API de S3 nuevamente
    /*const s3 = new AWS.S3();
    const params = {
      Bucket: "emmanueltc.db.tools",
    };

    s3.listObjectsV2(params, (err, data) => {
      if (err) {
        console.error(err);
      } else {
        const files = data.Contents.map((object) => object.Key);
        console.log("Archivos en el bucket:", files); // Agrega este registro
        setFileList(files);
      }
    });*//*
};*/

  if (isLoggedIn && location && location.state && location.state.fromTools) {
    return (
      <div className="App" style={{ padding: "10px", backgroundColor: isMobileMode ? '' : "#ff000040", margin: isMobileMode ? '' : isPCDrawerOpen ? "0 0 0 25.55vw" : "" }}>
        {isMobileMode ? (
          <div>Modo Telefono</div>
        ) : (
          <div>Modo Computadora</div>
        )}

        {isPCDrawerOpen ? (
          <div>Estado Drawer PC: Abierto</div>
        ) : (
          <div>Estado Drawer PC: Cerrado</div>
        )}

        <h1 style={{ textAlignLast: "center", fontSize: "5vw", fontWeight: "bolder", paddingBottom: "0vw" }}>Administrar Archivos</h1>
        <FileManager fileList={fileList} onDeleteFile={deleteFile} updateFileList={updateFileList} />

        <div style={{ padding: "20px" }}></div>

        <h1 style={{ textAlignLast: "center", fontSize: "5vw", fontWeight: "bolder" }}>Subir Archivos</h1>
        <FileUploader updateFileList={updateFileList} />
      </div >

    );
  } else {

    // Si el usuario intentó acceder directamente a través de la URL
    navigate("/", { replace: true }); // Agrega { replace: true } para reemplazar la entrada del historial
    return null; // O cualquier otro contenido que desees mostrar mientras se realiza la redirección
  }
};
/*
  return (
    <div className="App" style={{ padding: '10px' }}>
      <h1 style={{ textAlignLast: 'center', fontSize: '5vw', fontWeight: 'bolder', paddingBottom: '0vw' }}>Administrar Archivos</h1>
      <FileManager fileList={fileList} onDeleteFile={deleteFile} updateFileList={updateFileList} />

      <div style={{ padding: '20px' }}></div>

      <h1 style={{ textAlignLast: 'center', fontSize: '5vw', fontWeight: 'bolder' }}>Subir Archivos</h1>
      <FileUploader updateFileList={updateFileList} />
    </div>
  );
};*/

export default Archivos;
