// aws-config.js
const AWS = require("aws-sdk");

AWS.config.update({
  accessKeyId: "AKIARULFBYNKWMHJWHFL",
  secretAccessKey: "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",
  region: "us-east-2",
});

module.exports = AWS;
