import React from 'react';
import { makeStyles } from '@mui/styles';
import Footer from './Footer';
import Drawer from './Drawer';
import DrawerSwipeable from './DrawerSwipeable';

//const alturaDrawerTop = 7.50;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        paddingTop: "7.5vh",
        //paddingLeft: "11.11vw",
        height: '95vh',
    },
    /*toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: "10.69px",
        height: `${alturaDrawerTop}vh`,
    },*/
}));

const Layout = ({ children, isMobileMode, onToggleMode, isPCDrawerOpen, setPCDrawerOpen }) => {
    const classes = useStyles();

    return (
        <div className={classes.root} style={{ display: 'flex' }}>
            <Drawer isMobileMode={isMobileMode} onToggleMode={onToggleMode} isPCDrawerOpen={isPCDrawerOpen} setPCDrawerOpen={setPCDrawerOpen} />
            <DrawerSwipeable />
            <div id="IDHeaderContent" className={classes.content} style={{ paddingTop: "7.5vh" }}>
                <div /*className={classes.toolbar}*/ />
                {children}
                <Footer />
            </div>
        </div>
    );
};

export default Layout;
