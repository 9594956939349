import React from "react";
import { makeStyles } from '@mui/styles';
//import Typical from 'react-typical';
import "./styles.css";
//import Button from '@mui/material/Button';
//import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  otro: {
    height: 'auto'
  },
  Dentro: {
    width: "100px", height: "100px",
    position: "relative", float: "left",
    backgroundColor: "#000000",
    margin: "0px 5px",
    color: "#ffffff",
    fontWeight: "bold"
  },
  column: {
    float: "left",
    width: "33.33%",
    padding: "5px"
  }
}))

function Nosotros() {
  // Nosotros 
  const classes = useStyles();

  //document.getElementById("HomeTopBar").style.display = "block";

  return (
    <div>
      <div className={classes.otro}>
        <div className="App">
          <header className="App-header">

            {/*<h4><code>Burros Y Tacos El Maik.</code></h4>*/}

            {/*<p style={{ fontFamily: 'monospace' }}>
              Aqui podras encontrar el Menu, Ubicacion, Contactos, dejar comentarios, etc.
  </p>*/}

            <br />

            Nosotros

            <br />

          </header>
        </div>
      </div>
    </div>
  );
}

export default Nosotros;