import React/*, { useEffect }*/, { useState, useEffect } from "react";
import Skeleton from '@mui/material/Skeleton';
import 'bootstrap/dist/css/bootstrap.min.css';
import { motion as m } from "framer-motion";

const config = {
    //rootMargin: "bottom right up left",
    rootMargin: "0px 0px 0px 0px",
    threshold: 0.3,
};

function Imagen(props) {
    // eslint-disable-next-line
    const [loaded, setLoaded] = useState({});
    const [imageStatus, setImageStatus] = useState({});

    useEffect(() => {
        const myArray = [10, 20, 30, 40, 50];
        myArray.forEach((el) => {
            console.log(el)
        });

        let observer = new window.IntersectionObserver(function (entries, self) {
            console.log("Detectando...");

            setTimeout(() => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        loadImages(entry.target);
                        self.unobserve(entry.target);
                    }
                });
            }, 100);
        }, config);

        const imgs = document.querySelectorAll("[data-src]");
        imgs.forEach((img) => {
            observer.observe(img);
        });
        return () => {
            imgs.forEach((img) => {
                observer.unobserve(img);
            });
        };
    }, []);

    const loadImages = (image) => {
        const src = image.dataset.src;
        const img = new Image();
        img.onload = () => {
            setLoaded(prevState => ({ ...prevState, [src]: true }));
            setImageStatus(prevState => ({ ...prevState, [src]: true }));
        }
        img.src = src;
    };

    return (
        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-6 col-6" style={{ display: "flex", justifyContent: "center" }}>
            {imageStatus[props.path] ? (
                <m.img
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1.0 }}
                    transition={{ duration: 0.5, ease: "easeOut" }}
                    exit={{ scale: 0.0, y: "-100vh", x: "0vw" }}
                    src={props.path}
                    data-src={props.path}
                    className={"mientras1"}
                    alt={props.titulo}
                    style={{ margin: "10px 0px 0px 0px", maxWidth: "100%" }}
                />
            ) : (
                <div /*className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-6 col-6"*/ data-src={props.path} style={{ display: "flex", width: "100%" }} >
                    <Skeleton
                        animation="wave"
                        sx={{
                            marginTop:"10px", bgcolor: '#60544600', display: "inline-grid", borderStyle: "solid", borderWidth: "0.1vw", borderColor: "black",
                            //width: { xxl:"33vw", xl:"33vw", lg: "33vw", md: "50vw", sm: "50vw", xs: "50vw" },
                            height: { xxl:"50vh", xl:"50vh", lg: "50vh", md: "50vw", sm: "50vw", xs: "50vw" }
                        }}
                        variant="rectangular"
                        width={"100%"}
//                        height={"50vh"}
                    />
                </div>
            )}
        </div>
    );
}

export default Imagen;