//#region Imports
import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";

// Asegúrate de utilizar la importación correcta para useLocalStorage
import useLocalStorage from 'use-local-storage';
import 'bootstrap/dist/css/bootstrap.min.css';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';

import AWS from "./S3/aws-config";

//#endregion

function checkUserCredentials(username, password) {
  const s3 = new AWS.S3();

  const params = {
    Bucket: 'emmanueltc.db.users',
    Key: 'users.json',
  };

  return new Promise((resolve, reject) => {
    s3.getObject(params, (err, data) => {
      if (err) {
        reject(err);
      } else {
        const users = JSON.parse(data.Body.toString());

        const user = users.find((user) => user.username === username && user.password === password);

        if (user) {
          resolve(user);
        } else {
          reject(new Error('Credenciales incorrectas'));
        }
      }
    });
  });
}

const Cols4 = "col-4 col-lg-4 col-md-4 col-sm-4 col-xs-4";
const Cols8 = "col-8 col-lg-8 col-md-8 col-sm-8 col-xs-8";

const theme = createTheme({
  status: {
    danger: '#e53e3e',
  },
  palette: {
    primary: {
      main: '#0971f1',
      darker: '#053e85',
    },
    otro: {
      main: '#ff0000',
      darker: '#ff0000',
    },
    neutral: {
      main: '#64748B',
      contrastText: '#fff',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  otro: {
    height: 'auto'
  },
  MyCustomButton: {
    color: "blue",
    backgroundColor: "#00ff0050",
    '&:hover': {
      backgroundColor: "#ff000050"
    }
  }
}));

function LoginForm({ onLogin }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = () => {
    checkUserCredentials(username, password)
      .then(() => {
        onLogin(username);
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  return (
    <div>
      <h2>Iniciar Sesión</h2>
      <input
        type="text"
        placeholder="Usuario"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        onKeyPress={(e) => e.key === 'Enter' && handleLogin()}
      />
      <br />
      <input
        type="password"
        placeholder="Contraseña"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        onKeyPress={(e) => e.key === 'Enter' && handleLogin()}
      />
      <br />
      <button onClick={handleLogin}>Iniciar Sesión</button>
    </div>
  );
}

function Welcome({ username, onLogout, isLoggedIn }) {
  const navigate = useNavigate();

  const handleNavigateToArchivos = () => {
    // Redirigir a Archivos.js y pasar la propiedad 'fromTools' y 'isLoggedIn'
    navigate('/archivos', { state: { fromTools: true, isLoggedIn: isLoggedIn } });
  };

  return (
    <div>
      <div className="row GutterX0">
        <p className={Cols8}>Perfil: {username}.</p>
        <button className={Cols4} onClick={onLogout}>Cerrar Sesión</button>
      </div>

      <ThemeProvider theme={theme}>
        <Button
          onClick={handleNavigateToArchivos}
          style={{ display: "block" }}
          variant="contained"
          color="otro"
          sx={{ mb: 1, bgcolor: "var(--text-primary)", width: 350, height: 35, color: "var(--background)" }}
        >
          Ir a Archivos
        </Button>
        {/*}        <Button style={{ display: "block" }} variant="contained" color="otro" component={Link}
          to={{
            pathname: '/archivos',
            state: { fromTools: true } // Indica que proviene de Tools
          }}
          sx={{ mb: 1, bgcolor: "var(--text-primary)", width: 350, height: 35, color: "var(--background)" }}>Archivos</Button>*/}
      </ThemeProvider>

      <hr style={{ color: "red", backgroundColor: "red", width: "100%", margin: "10px 0px 0px 0px" }} />
      <div className="GutterX0 row">
      </div>
      <div style={{ padding: "10px" }}></div>
    </div>
  );
}

function Tools() {
  const classes = useStyles();

  // Cambié el nombre de las variables almacenadas para ser más descriptivo
  const storedUserDataInitial = { loggedIn: false, username: '' };
  const [storedUserData, setStoredUserData] = useLocalStorage('userData', storedUserDataInitial);
  const [isLoggedIn, setLoggedIn] = useState(storedUserData.loggedIn);

  useEffect(() => {
    setLoggedIn(storedUserData.loggedIn);
  }, [storedUserData.loggedIn]);

  const handleLogin = (username) => {
    setLoggedIn(true);
    // Actualizo los datos almacenados
    setStoredUserData({ loggedIn: true, username });
  };

  const handleLogout = () => {
    setLoggedIn(false);
    // Actualizo los datos almacenados
    setStoredUserData({ loggedIn: false, username: '' });
  };

  return (
    <div>
      <div className={classes.otro}>
        <div className="App">
          <header className="App-header">
            <h1>Tools / Herramientas</h1>

            <hr style={{ color: "red", backgroundColor: "red", width: "100%", margin: "10px 0px 0px 0px" }} />
            <div style={{ width: "95%", display: "contents", textAlignLast: "center" }}>
              {isLoggedIn ? (
                <Welcome username={storedUserData.username} onLogout={handleLogout} />
              ) : (
                <LoginForm onLogin={handleLogin} />
              )}
            </div>
            <hr style={{ color: "red", backgroundColor: "red", width: "100%", margin: "10px 0px 0px 0px" }} />
          </header>
        </div>
      </div>
    </div>
  );
}

export default Tools;
