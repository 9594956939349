import React from "react";
import { makeStyles } from '@mui/styles';
//import Typical from 'react-typical';
import "./styles.css";
//import Button from '@mui/material/Button';
//import { Link } from 'react-router-dom';
import { Box } from '@mui/material';
import Facebook from "../../assets/img/Logo_Facebook.png";
import Instagram from "../../assets/img/Logo_Instagram.png";
import TikTok from "../../assets/img/Logo_TikTok.png";

const useStyles = makeStyles((theme) => ({
  otro: {
    height: 'auto'
  },
  Dentro: {
    width: "100px", height: "100px",
    position: "relative", float: "left",
    backgroundColor: "#000000",
    margin: "0px 5px",
    color: "#ffffff",
    fontWeight: "bold"
  },
  column: {
    float: "left",
    width: "33.33%",
    padding: "5px"
  }
}))

function Redes() {
  // Redes 
  const classes = useStyles();

  //document.getElementById("HomeTopBar").style.display = "block";

  return (
    <div>
      <div className={classes.otro}>
        <div className="App">
          <header className="App-header">

            {/*<h4><code>Burros Y Tacos El Maik.</code></h4>*/}

            {/*<p style={{ fontFamily: 'monospace' }}>
              Aqui podras encontrar el Menu, Ubicacion, Contactos, dejar comentarios, etc.
  </p>*/}

            <br />

            Redes

            <br />
            <br />

            <Box >
              <a href="https://www.facebook.com/BurrosyTacosElMaik/" target="_blank" rel="noreferrer">
                <Box
                  component="img"
                  sx={{
                    marginRight: "10px",
                    height: "5vh",
                    width: "5vh",
                    maxHeight: { xs: 100, md: 100 },
                    maxWidth: { xs: 100, md: 100 },
                  }}
                  alt="Facebook."
                  src={Facebook}
                />
              </a>
              <a href="https://www.instagram.com/burrosytacoselmaik/" target="_blank" rel="noreferrer">
                <Box
                  component="img"
                  sx={{
                    height: "5vh",
                    width: "5vh",
                    maxHeight: { xs: 100, md: 100 },
                    maxWidth: { xs: 100, md: 100 },
                  }}
                  alt="Instagram."
                  src={Instagram}
                />
              </a>
              <a href="https://www.tiktok.com/@burrosytacoselmaik" target="_blank" rel="noreferrer">
                <Box
                  component="img"
                  sx={{
                    marginLeft: "10px",
                    height: "5vh",
                    width: "5vh",
                    maxHeight: { xs: 100, md: 100 },
                    maxWidth: { xs: 100, md: 100 },
                  }}
                  alt="TikTok."
                  src={TikTok}
                />
              </a>
            </Box>

            <br />

          </header>
        </div>
      </div>
    </div>
  );
}

export default Redes;