import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
//import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import Burro from "../../assets/icons/Burro.png";

export default function SwipeableTemporaryDrawer() {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false, //Cambiar a true para que aparesca desde que se entra a la pagina
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >

      <List>
        {[
        /*---------------------------------- START ERROR TWO CHILDRE */
        //<InboxIcon />,
        /*---------------------------------- END ERROR TWO CHILDRE */
        <Box
          component="img"
          sx={{
            height: "5vh",
            width: "5vh",
            maxHeight: { xs: 233, md: 167 },
            maxWidth: { xs: 350, md: 250 },
          }}
          alt="Imagen."
          src={Burro}
        />,
        
        'Send semail', 'Drafts'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <InboxIcon />
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                <MailIcon />
              </ListItemIcon>
              <ListItemText primary="1" />
              <ListItemText primary={text} />
              <ListItemText primary="2" />
            </ListItemButton>
          </ListItem>
        ))}
        
        <ListItem key="Burro" disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <Box
                component="img"
                sx={{
                  height: "5vh",
                  width: "5vh",
                  maxHeight: { xs: 233, md: 167 },
                  maxWidth: { xs: 350, md: 250 },
                }}
                alt="Imagen."
                src={Burro}
              />
            </ListItemIcon>

            <ListItemText primary="Burro" />
          </ListItemButton>
        </ListItem>
      </List>

      <Divider />

      <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      {/*['left', 'right', 'top', 'bottom'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))*/

        ['left', 'right', /*'top',*/ 'bottom'].map((anchor) => (
          <React.Fragment key={anchor}>
            {/*<Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button>*/}
            <SwipeableDrawer
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
              onOpen={toggleDrawer(anchor, true)}
            >
              {list(anchor)}
            </SwipeableDrawer>
          </React.Fragment>
        ))}

      {/*<React.Fragment key={'left'}>
        <Button onClick={toggleDrawer('left', true)}>{'left'}</Button>
        <SwipeableDrawer
          left={'left'}
          open={state['left']}
          onClose={toggleDrawer('left', false)}
          onOpen={toggleDrawer('left', true)}
        >
          {list('left')}
        </SwipeableDrawer>
        </React.Fragment>*/}
    </div>
  );
}
