import React from 'react'
//import { makeStyles } from '@mui/styles'
//import { Typography, /*Container,*/ Box } from '@mui/material';
//import Imagen from "../../assets/img/Imagen.png";
//import Taco from "../../assets/icons/Burro.png";
import { /*styled,*/ useTheme } from '@mui/material/styles';

import { useCallback } from "react";
import Particles from "react-particles";
import { loadFull } from "tsparticles";

/*const useStyles = makeStyles((theme) => ({
    footer: {
        backgroundColor: '#ffffff',
        marginTop: '0px',
        position: 'relative',
        left: '0',
        bottom: '0',
        right: '0',
        paddingRight: "0px",
        paddingLeft: "0px"
    },
    footerText: {
        paddingRight: '4px',
        color: 'black',
        fontWeight: 'bold',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    logo: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    }

}))*/

const Footer = () => {
    //const classes = useStyles();

    const theme = useTheme();

    const particlesInit = useCallback(async engine => {
        //console.log(engine);
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(engine);
    }, []);

    const particlesLoaded = useCallback(async container => {
        //await console.log(container);
    }, []);

    return (
        <div>
            <div style={{ backgroundColor: "var(--background)", position: "fixed", left: "0", bottom: "0", right: "0", zIndex: "10000", height: "6.5vh" }} /*maxWidth="xl"*/>

                <div style={{ position: "fixed", height: "6.5vh", width: "100%" }}>

                    <Particles id="tsparticlesFooter" style={{ position: "absolute", top: "0", left: "0", width: "100%", height: "100%", zIndex: "111111", outline: "solid 0px orange", outlineOffset: "-1px" /*borderWidth:1, borderStyle:"solid",borderColor:"red"*/ }}
                        init={particlesInit}
                        loaded={particlesLoaded}
                        options={{
                            fullScreen: { enable: false },
                            "fpsLimit": 30,
                            "particles": {
                                "number": {
                                    "value": 100,
                                    "density": {
                                        "enable": true,
                                        "value_area": 2000
                                    }
                                },
                                "color": {
                                    "value": "#787878", //Color de los circulos
                                    "animation": {
                                        "enable": false,
                                        "speed": 20,
                                        "sync": true
                                    }
                                },
                                "shape": {
                                    "type": "circle"
                                },
                                "opacity": {
                                    "value": 0.5,
                                    "random": false,
                                    "anim": {
                                        "enable": false,
                                        "speed": 3,
                                        "opacity_min": 0.1,
                                        "sync": false
                                    }
                                },
                                "size": {
                                    "value": 4, //Tamaño de los circulos
                                    "random": true,
                                    "anim": {
                                        "enable": true,
                                        "speed": 20,
                                        "size_min": 0.1,
                                        "sync": false
                                    }
                                },
                                "line_linked": {
                                    "enable": true,
                                    "distance": 100,
                                    "color": "random",
                                    "opacity": 0.4,
                                    "width": 1,
                                    "triangles": {
                                        "enable": true,
                                        "color": "#787878", //Color de los triangulos
                                        "opacity": 0.5
                                    }
                                },
                                "move": {
                                    "enable": true,
                                    "speed": 6,
                                    "direction": "none",
                                    "random": false,
                                    "straight": false,
                                    "out_mode": "out",
                                    "attract": {
                                        "enable": false,
                                        "rotateX": 600,
                                        "rotateY": 1200
                                    }
                                }
                            },
                            "interactivity": {
                                "detect_on": "window",
                                "events": {
                                    "onhover": {
                                        "enable": true,
                                        "mode": "grab",
                                        "parallax": {
                                            "enable": true,
                                            "smooth": 10,
                                            "force": 60
                                        }
                                    },
                                    "onclick": {
                                        "enable": true,
                                        "mode": ["remove", "push"]
                                    },
                                    "resize": true
                                },
                                "modes": {
                                    "grab": {
                                        "distance": 300,
                                        "line_linked": {
                                            "opacity": 1
                                        }
                                    },
                                    "bubble": {
                                        "distance": 400,
                                        "size": 40,
                                        "duration": 2,
                                        "opacity": 0.8
                                    },
                                    "repulse": {
                                        "distance": 200
                                    },
                                    "push": {
                                        "particles_nb": 20
                                    },
                                    "remove": {
                                        "particles_nb": 20
                                    }
                                }
                            },
                            "retina_detect": true,
                            "background": {
                                "color": "#000000000", //Color de fondo de particulas
                                "image": "",
                                "position": "50% 50%",
                                "repeat": "no-repeat",
                                "size": "cover"
                            }

                        }}
                    />

                </div>

                <div className='App' data-theme={theme} style={{ boxShadow: "0px 0px 0px 1px rgb(100 100 100 / 50%)" }}>
                    {/*<Box display="flex" style={{ paddingTop: '0.5vh', paddingBottom: '0.5vh', paddingLeft: "5px", paddingRight: "5px" }}>

                        <Box justifyContent="flex-end" display="flex" flexGrow={1} visibility="visible">
                            <Box display="flex">
                                <Box style={{ display: 'flex' }} ><Typography className={classes.footerText} variant="h6" style={{ fontSize: "1.2vh", color:"var(--text-primary)" }} >2023</Typography></Box>
                            </Box>
                            <Box style={{ display: 'flex' }} ><Typography className={classes.footerText} variant="h6" style={{ fontSize: "1.2vh", color:"var(--text-primary)" }} >@</Typography></Box>
                            <Box display='flex' flexGrow={1}><Typography className={classes.footerText} variant="h6" style={{ fontSize: "1.2vh", color:"var(--text-primary)" }} >EmmanuelTC</Typography></Box>
                        </Box>

                        <Box id="HomeTopBarFalsa" justifyContent="center" display='flex' flexGrow={1}>
                            <Box
                                component="img"
                                sx={{
                                    height: "5.5vh",
                                    width: "5.5vh",
                                    //                                    maxHeight: { xs: 233, md: 167 },
                                    //                                   maxWidth: { xs: 350, md: 250 },
                                }}
                                alt="Imagen."
                                src={Imagen}
                            />
                        </Box>

                        <Box justifyContent="flex-end" display="flex" flexGrow={1} visibility="visible">
                            <Box display="flex">
                                <Box style={{ display: 'flex' }} ><Typography className={classes.footerText} variant="h6" style={{ fontSize: "1.2vh", color:"var(--text-primary)" }} >Contacto</Typography></Box>
                            </Box>
                            <Box style={{ display: 'flex' }} ><Typography className={classes.footerText} variant="h6" style={{ fontSize: "1.2vh", color:"var(--text-primary)" }} >:</Typography></Box>
                            <Box display='flex'><Typography className={classes.footerText} variant="h6" style={{ fontSize: "1.2vh", color:"var(--text-primary)" }} >...</Typography></Box>
                        </Box>

                    </Box>*/}
                </div>
            </div>
        </div>
    )
}

export default Footer
