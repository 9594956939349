import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from '@mui/styles';
import 'bootstrap/dist/css/bootstrap.min.css';
// eslint-disable-next-line
import { Link, useNavigate } from 'react-router-dom';
import { motion as m } from "framer-motion";

//import { ReactComponent as KannaProductionSVG } from '../../assets/Kanna_Production.svg';

import { useFollowPointer } from "../Elements/use-follow-pointer";

import { useCallback } from "react";
import Particles from "react-particles";
import { loadFull } from "tsparticles";

//import Particles from "react-tsparticles";
import { polygonPathName, loadPolygonPath } from "tsparticles-path-polygon";

const config = {
    //rootMargin: "bottom right up left",
    rootMargin: "0px 0px 0px 0px",
    threshold: 1.0,
};

//const alturaHome = 100;
// eslint-disable-next-line
const alturaDrawerTop = 50;
//const AlturaPaddingDrawer = alturaDrawerTop / 2;
// eslint-disable-next-line
const TamanoIcono = 100;


const useStyles = makeStyles((theme) => ({
    otro: {
        height: 'auto'
    },
    Dentro: {
        width: "100px", height: "100px",
        position: "relative", float: "left",
        backgroundColor: "#000000",
        margin: "0px 5px",
        color: "#ffffff",
        fontWeight: "bold"
    },
    column: {
        float: "left",
        width: "33.33%",
        padding: "5px"
    },
    Container: {
        width: "100%"
    },
    Row: {
        marginRight: "0px"
    }
}))

function InicioKanna(props) {
    // eslint-disable-next-line
    const [loaded, setLoaded] = useState({});
// eslint-disable-next-line
    const [imageStatus, setImageStatus] = useState({});

    // eslint-disable-next-line
    const navigate = useNavigate();

    const classes = useStyles();

    /** Array a H1 con un forEach */
    const studentDetails = ['Alex', 'Anik', 'Deven', 'Rathore'];
    const names = [];
    studentDetails.forEach((data) => {
        names.push(<h3 className='student_name'>{data}</h3>)
    })

    useEffect(() => {
        let observer = new window.IntersectionObserver(function (entries, self) {
            console.log("Detectando...");

            setTimeout(() => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        loadImages(entry.target);
                        self.unobserve(entry.target);
                    }
                });
            }, 100);
        }, config);

        const imgs = document.querySelectorAll("[data-src]");
        imgs.forEach((img) => {
            observer.observe(img);
        });
        return () => {
            imgs.forEach((img) => {
                observer.unobserve(img);
            });
        };
    }, []);

    const loadImages = (image) => {
        const src = image.dataset.src;
        const img = new Image();
        img.onload = () => {
            setLoaded(prevState => ({ ...prevState, [src]: true }));
            setImageStatus(prevState => ({ ...prevState, [src]: true }));
        }
        img.src = src;
    };

    const tiempo = 1;
    // eslint-disable-next-line
    const container = {
        inicial: {
            y: 0,
            opacity: 0,
            scale: 1
        },
        animar: {
            y: 0,
            opacity: 1,
            scale: 1,
            transition: {
                delay: tiempo,
                duration: tiempo,
                delayChildren: tiempo + 0.5, //Lo que tarda para empezar los children a mostrarse
                staggerChildren: 0.3 //Tiempo entre childrens
            }
        }
    };

    // eslint-disable-next-line
    const item = {
        inicial: {
            y: 20,
            opacity: 0.0,
            scale: 0.0
        },
        animar: {
            y: 0,
            opacity: 1,
            scale: 1.0/*,
            transition:{
                duration:4
            }*/
        }
    };

    const PCRef = useRef(null)

    const ref = useRef(null);
    const { x, y } = useFollowPointer(ref);

    /*    const particlesInit = useCallback(async engine => {
            console.log(engine);
            // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
            // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
            // starting from v2 you can add only the features you need reducing the bundle size
            await loadFull(engine);
        }, []);*/

    const particlesInit = useCallback(async (engine) => {
        console.log(engine);

        await loadFull(engine);
        await loadPolygonPath(engine);
    }, []);

    const particlesLoaded = useCallback(async container => {
        //await console.log(container);
    }, []);

    return (
        <m.div style={{/*overflowY:"scroll"*/ }}
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.75, ease: "easeOut" }}
            exit={{ opacity: 1 }}
        >
            <div className={classes.otro}>
                <div className="App" style={{ overflowY: "hidden", overflowX: "hidden" }}>
                    <header className="App-container" style={{ display: "flex" }}>
                        <div className="PadreTS" /*style={{ position: "fixed", height: "500px", width: "500px" }}*/>

                            <Particles id="tsparticles2" style={{ position: "fixed", top: "6.6vh", left: "0", width: "10%", height: "10%" }}
                                init={particlesInit}
                                loaded={particlesLoaded}
                                options={{
                                    fullScreen: { enable: false },
                                    "fpsLimit": 140,
                                    particles: {
                                        number: {
                                            density: {
                                                enable: true,
                                                area: 800
                                            },
                                            value: 0
                                        },
                                        color: {
                                            value: "#7932BC",
                                            animation: {
                                                enable: true,
                                                speed: 10
                                            }
                                        },
                                        shape: {
                                            type: "circle"
                                        },
                                        opacity: {
                                            value: 1
                                        },
                                        size: {
                                            value: 2
                                        },
                                        move: {
                                            attract: {
                                                enable: false,
                                                distance: 100,
                                                rotate: {
                                                    x: 2000,
                                                    y: 2000
                                                }
                                            },
                                            direction: "none",
                                            enable: true,
                                            outModes: {
                                                default: "destroy"
                                            },
                                            path: {
                                                clamp: false,
                                                enable: true,
                                                delay: {
                                                    value: 0
                                                },
                                                generator: polygonPathName,
                                                options: {
                                                    sides: 6,
                                                    turnSteps: 30,
                                                    angle: 30
                                                }
                                            },
                                            random: false,
                                            speed: 3,
                                            straight: false,
                                            trail: {
                                                fillColor: "#000",
                                                length: 40,
                                                enable: true
                                            }
                                        },

                                    },
                                    background: {
                                        color: "#000"
                                        /**
                                         * "color": "#000000000", //Color de fondo de particulas
                                        "image": "",
                                        "position": "50% 50%",
                                        "repeat": "no-repeat",
                                        "size": "cover"
                                         */
                                    },
                                    emitters: {
                                        direction: "none",
                                        rate: {
                                            quantity: 1,
                                            delay: 0.25
                                        },
                                        size: {
                                            width: 0,
                                            height: 0
                                        },
                                        position: {
                                            x: 50,
                                            y: 50
                                        }
                                    },
                                    "interactivity": {
                                        "detect_on": "window",
                                        "events": {
                                            "onhover": {
                                                "enable": true,
                                                "mode": "grab",
                                                "parallax": {
                                                    "enable": true,
                                                    "smooth": 10,
                                                    "force": 60
                                                }
                                            },
                                            "onclick": {
                                                "enable": true,
                                                "mode": ["remove", "push"]
                                            },
                                            "resize": true
                                        },
                                        "modes": {
                                            "grab": {
                                                "distance": 300,
                                                "line_linked": {
                                                    "opacity": 1,
                                                    "color": "random"
                                                }
                                            },
                                            "bubble": {
                                                "distance": 400,
                                                "size": 40,
                                                "duration": 2,
                                                "opacity": 0.8
                                            },
                                            "repulse": {
                                                "distance": 200
                                            },
                                            "push": {
                                                "particles_nb": 20
                                            },
                                            "remove": {
                                                "particles_nb": 20
                                            }
                                        }
                                    },
                                    "retina_detect": true,
                                }}
                            />
                        </div>

                        <m.div
                            initial={{ height: "94.6vh", width: "100vw" }}
                            animate={{ delay: 1, height: "94.6vh" }}
                            transition={{ delay: 0, duration: 1.3, ease: "easeOut" }}
                            exit={{ opacity: 0 }}
                            className="row"
                        >
                            <m.div className="col-md-12" style={{ position: "absolute", display: "initial", justifyItems: "center" }}>InicioKanna</m.div>

                            {/*<KannaProductionSVG className="col-md-12" height="10.0vh" width="60.0vw" style={{ position: "absolute", display: "initial", justifyItems: "center" }} /> {/** Kanna static */}

                            <m.div className="containerDrag col-3" style={{ backgroundColor: "#050505", color: "orange", display: "flex", justifyContent: "center", alignItems: "center"/*, backgroundColor: "#FEFEFE"*/ }}
                                initial={{ opacity: 0, height: "94.6vh", width: "100vw" }}
                                animate={{ delay: 1, opacity: 1, height: "94.6vh" }}
                                transition={{ delay: 0, duration: 1.3, ease: "easeOut" }}
                                exit={{ opacity: 0 }}
                                ref={PCRef}
                            >
                                <m.div drag dragElastic={0.7} whileTap={{ cursor: "grabbing" }} dragConstraints={PCRef}
                                    style={{ width: "min-content", border: "#00000000 1px solid" }}
                                    whileDrag={{
                                        scale: [1, 4, 3, 4, 3, 4, 3, 4, 3, 4, 1],
                                        rotate: [0, 10, -10, 10, -10, 10, -10, 10, -10, 10, -10, 10, -10, 10, -10, 10, -10, 10, 0],
                                        transition: {
                                            duration: 10,
                                            ease: "easeInOut",
                                            repeat: Infinity,
                                            repeatDelay: 0
                                        },
                                    }}
                                    whileHover={{ scale: 0.9, transition: { duration: 1 } }}
                                    onHoverStart={e => { }}
                                    onHoverEnd={e => { console.log("hola") }}
                                >

                                    <m.div
                                        initial={{
                                            scale: 4,
                                        }}
                                        animate={{
                                            rotate: 360000//[0, 360, -360, 360, -360, 360, -360, 360, -360, 360, -360, 360, -360, 360, -360, 360, -360, 360, 0],
                                        }}
                                        transition={{
                                            duration: 100,
                                            ease: "anticipate",//[0.17, 0.67, 0.83, 0.67]//"linear"//"easeIn","easeOut","easeInOut"//"circIn", "circOut", "circInOut"//"backIn", "backOut", "backInOut"//"anticipate"
                                            repeat: Infinity,
                                            repeatDelay: 0,
                                            type: "tween",//spring//tween//inertia
                                            stiffness: 10000,
                                            velocity: 50
                                        }}
                                    >
                                        {/*<KannaProductionSVG height="6.0vh" width="6.0vw" /> {/** Kanna vueltas */}
                                    </m.div>
                                </m.div>
                            </m.div>

                        </m.div>

                        <m.div
                            ref={ref}
                            style={{ borderRadius: "50%", background: "transparent", zIndex: "100000000000", pointerEvents: "none" }}
                            animate={{ x, y }}
                            transition={{
                                type: "spring",//spring//tween//inertia
                                damping: 1,//3,//1//3
                                stiffness: 500000,//50,//1//500000
                                restDelta: 0.001,//0.001//0.001//1000000
                            }}
                        >
                           {/* <KannaProductionSVG height="3.0vh" width="3.0vw" style={{pointerEvents: "none"}} /> {/** Kanna mouse */}
                        </m.div>

                        <div style={{ display: "none" }}> {/** Circulos Animados Infinitos */}
                            {/*
                            <m.div
                                initial={{ scale: 0.0, opacity: 0 }}
                                animate={{
                                    opacity: 1,
                                    scale: [0.1, 0.5, 0.5, 0.1, 0.1],
                                    rotate: [0, 0, 180, 180, 0],
                                    borderRadius: ["0%", "0%", "50%", "50%", "0%"]
                                }}
                                transition={{
                                    delay: 1,
                                    duration: 2,
                                    ease: "easeInOut",
                                    times: [0, 0.2, 0.5, 0.8, 1],
                                    repeat: Infinity,
                                    repeatDelay: 1
                                }}
                                exit={{ scale: 0.0, y: "-50vh", x: "0vw" }}
                                style={{
                                    position: "absolute", top: "0%", left: "0%", transform: "translate(-50%, -50%)",
                                    margin: "0vh", width: "6.6vh", height: "6.6vh", backgroundColor: "#7932BC"
                                }}
                            />

                            <m.div
                                initial={{ scale: 0.0, opacity: 0 }}
                                animate={{
                                    opacity: 1,
                                    scale: [0.1, 0.5, 0.1],
                                    rotate: [0, 720, 0],
                                    borderRadius: ["0%", "50%", "0%"]
                                }}
                                transition={{
                                    delay: 1,
                                    duration: 2,
                                    ease: "easeInOut",
                                    times: [0, 0.5, 1],
                                    repeat: Infinity,
                                    repeatDelay: 0
                                }}
                                exit={{ scale: 0.0, y: "-50vh", x: "0vw" }}
                                style={{
                                    position: "absolute", top: "0%", right: "0%", transform: "translate(-50%, -50%)",
                                    margin: "0vh", width: "6.6vh", height: "6.6vh", backgroundColor: "#7932BC"
                                }}
                            />
                            */}
                        </div>

                    </header>
                </div>
            </div >
        </m.div >
    );
}

export default InicioKanna;