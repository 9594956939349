// eslint-disable-next-line
import React, { useState, useEffect, useMemo } from "react";
import { makeStyles } from '@mui/styles';
import 'bootstrap/dist/css/bootstrap.min.css';
import { motion as m } from "framer-motion";

const config = {
    //rootMargin: "bottom right up left",
    rootMargin: "0px 0px 0px 0px",
    threshold: 1.0,
};

const useStyles = makeStyles((theme) => ({
    otro: {
        height: 'auto'
    },
    Dentro: {
        width: "100px", height: "100px",
        position: "relative", float: "left",
        backgroundColor: "#000000",
        margin: "0px 5px",
        color: "#ffffff",
        fontWeight: "bold"
    },
    column: {
        float: "left",
        width: "33.33%",
        padding: "5px"
    },
    Container: {
        width: "100%"
    },
    Row: {
        marginRight: "0px"
    }
}))

function Twitch(props) {
    const [setLoaded] = useState({});
    const [setImageStatus] = useState({});

    // eslint-disable-next-line
    const classes = useStyles();

    /** Array a H1 con un forEach */
    const studentDetails = ['Alex', 'Anik', 'Deven', 'Rathore'];
    const names = [];
    studentDetails.forEach((data) => {
        names.push(<h3 className='student_name'>{data}</h3>)
    })
    /*
        const channel1 = 'lnsanie';
        const channel2 = 'imyztique';*/
        // eslint-disable-next-line
    const channels = ['kannasenpaii', 'paulettethehooe', 'pinkuberry', 'EmmanuelTC'];
    // eslint-disable-next-line
    var aux = 0; //Lo cree porque el useeffect siempre se hace 2 veces y con el aux obligo que hasta que se sume 2 veces 1 se haga solo 1 vez

    /*useEffect(() => {
        aux++;
        // Verificar si el script de Twitch ya está cargado
        if (window.Twitch && window.Twitch.Player) {
            // El script ya se cargó, crear los reproductores para cada canal
            if (aux === 2) {
                channels.forEach((channel, index) => {
                    new window.Twitch.Player(`twitch-embed-${index}`, {
                        channel,
                    });
                });
            }
        } else {
            // El script no se ha cargado, cargar el script y crear los reproductores para cada canal después de que se cargue
            const script = document.createElement('script');

            script.setAttribute('src', 'https://player.twitch.tv/js/embed/v1.js');
            script.setAttribute('charset', 'utf-8');
            script.setAttribute('type', 'text/javascript');
            script.setAttribute('width', '100%');
            script.setAttribute('height', '400%');

            script.onload = () => {
                channels.forEach((channel, index) => {
                    new window.Twitch.Player(`twitch-embed-${index}`, {
                        channel,
                    });
                });
            };

            document.body.appendChild(script);

            return () => {
                document.body.removeChild(script);
            };
        }
    }, []);*/



    /*useEffect(() => {
        const script = document.createElement('script');

        script.setAttribute('src', 'https://player.twitch.tv/js/embed/v1.js');
        script.setAttribute('charset', 'utf-8');
        script.setAttribute('type', 'text/javascript');

        script.onload = () => {
            new window.Twitch.Player('twitch-embed-1', {
                channel: channel1,
            });

            new window.Twitch.Player('twitch-embed-2', {
                channel: channel2,
            });
        };

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);*/

    useEffect(() => {
        let observer = new window.IntersectionObserver(function (entries, self) {
            console.log("Detectando...");

            setTimeout(() => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        loadImages(entry.target);
                        self.unobserve(entry.target);
                    }
                });
            }, 100);
        }, config);

        const imgs = document.querySelectorAll("[data-src]");
        imgs.forEach((img) => {
            observer.observe(img);
        });
        return () => {
            imgs.forEach((img) => {
                observer.unobserve(img);
            });
        };
    });

    const loadImages = (image) => {
        const src = image.dataset.src;
        const img = new Image();
        img.onload = () => {
            setLoaded(prevState => ({ ...prevState, [src]: true }));
            setImageStatus(prevState => ({ ...prevState, [src]: true }));
        }
        img.src = src;
    };

    return (
        <m.div style={{/*overflowY:"scroll"*/ }}
            initial={{ opacity: 1, backgroundColor: "#000000" }}
            animate={{ opacity: 1, backgroundColor: "#000000" }}
            transition={{ duration: 0.75, ease: "easeOut" }}
            exit={{ opacity: 1, backgroundColor: "#000000" }}
        >

{/*            <m.div
                initial={{ opacity: 1, x: "0vw", backgroundColor: "#00000050" }}
                animate={{ x: "100vw" }}
                transition={{ duration: 1.5, ease: "easeOut" }}
                exit={{ opacity: 1, x: "0vw", backgroundColor: "#00000050" }}
                style={{ width: "100%", height: "93.4vh", backgroundColor: "#00000050", position: "absolute" }} /
                >*/}

            <m.div
                initial={{ x: "100vw" }}
                animate={{ x: "0vw" }}
                transition={{ delay: 1, duration: 1.5, ease: "easeOut" }}
                //exit={{ opacity: 0, backgroundColor: "#000000" }}
                style={{ height: "93.4vh" /*height: "80vh"*/, backgroundColor: "#000000" }}
            >
                <m.div
                    initial={{ height: "93.4vh" }}
                    transition={{ duration: 1.5, ease: "easeOut" }}
                    exit={{ x: "-100vw", backgroundColor: "#000000" }}
                    style={{ height: "93.4vh" /*height: "80vh"*/, backgroundColor: "#000000" }}
                >
                    <iframe style={{ backgroundColor: "#000000" }} src={`https://embed.twitch.tv?channel=kannasenpaii&parent=${window.location.hostname}`} frameborder="0" allow="autoplay; fullscreen" title="Twitch" sandbox="allow-scripts allow-same-origin" width="100%" height="100%"></iframe>
                </m.div>
            </m.div>

            {/*
            <div style={{ display: 'flex', flexDirection: 'row', height: "80vh" }}>
                <iframe
                    title="kannasenpaii"
                    src={`https://player.twitch.tv/?channel=kannasenpaii&parent=${window.location.hostname}`}
                    frameBorder="0"
                    allowFullScreen={true}
                    scrolling="no"
                    height="100%"
                    width="70%"
                    style={{ flexGrow: 1, minWidth: '0' }} // para evitar que el video se desborde del ancho
                />
                <iframe
                    title="kannasenpaiiChat"
                    id="chat_embed"
                    src={`https://www.twitch.tv/embed/kannasenpaii/chat?parent=${window.location.hostname}&theme=dark`}
                    height="100%"
                    width="30%"
                    style={{ flexGrow: 0, minWidth: '0' }} // para evitar que el chat se desborde del ancho
                />
            </div>

            <div style={{ height: "80vh" }}>
                <iframe src={`https://embed.twitch.tv?channel=mym_alkapone&parent=${window.location.hostname}`} frameborder="0" allow="autoplay; fullscreen" title="Twitch" sandbox="allow-scripts allow-same-origin" width="100%" height="100%"></iframe>
            </div>

            <iframe src={`https://embed.twitch.tv?channel=mym_alkapone&height=720&parent=embed.example.com&parent=othersite.example.com&parent=${window.location.hostname}&referrer=https%3A%2F%2Fwww.alkapone.tv%2F&theme=dark&width=100%25`} allowfullscreen="" scrolling="no" frameborder="0" allow="autoplay; fullscreen" title="Twitch" sandbox="allow-modals allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox" width="100%" height="720"></iframe>
*/}

            {/*            <div className={classes.otro}>
                <div className="App" style={{ overflowY: "hidden", overflowX: "hidden" }}>
                    <header className="App-container" style={{ display: "flex" }}>
                        <div style={{ backgroundColor: "black", paddingTop: "0vh", paddingBottom: "1vh" }}>
                            <h1 style={{ margin: 0, color: "white" }}>Nahualtecas</h1>
                        </div>
                        <div className="row">
                            {channels.map((channel, index) => (
                                <div key={index} id={`twitch-embed-${index}`} className="col-md-6" style={{ flexGrow: 1, minWidth: "0" }} ></div>
                            ))}
                        </div>

                    </header>
                </div>
            </div >*/}
        </m.div >
    );
}

export default Twitch;