import React, { useEffect } from "react";
import { makeStyles } from '@mui/styles';
import 'bootstrap/dist/css/bootstrap.min.css';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { motion as m } from "framer-motion";
import KannaLogo from "../../assets/kanna_logo.png";
import Ahegao from "../../assets/ahegao_background.jpg";

const useStyles = makeStyles((theme) => ({
    otro: {
        height: 'auto'
    },
    Container: {
        width: "100%"
    },
    Row: {
        marginRight: "0px"
    }
}))



function Carga() {
    // Redes 
    const navigate = useNavigate();
    const classes = useStyles();

    useEffect(() => {
        //console.log("HOLAAAAAAAAAAAAAA");

        setTimeout(() => {
            //console.log("Adios");

            setTimeout(() => {
                //window.location.replace('/inicio');
                navigate('/inicio');
            }, 2000);

        }, 500);
    }, [navigate]); //Se puede quitar el navigate y en vez de eso poner eslint-disable-next-line arriba de esta linea para solo ocultar el warning

    return (
        <m.div
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.25, ease: "easeOut" }}
            exit={{ opacity: 1 }}
        >

            <m.div
                initial={{ backgroundColor: "#ffffff" }}
                animate={{ backgroundColor: "#00000099" }}
                transition={{ delay: 0.4, }}
                exit={{ backgroundColor: "#000000" }}
                style={{ width: "100vw", height: "100vh", position: "absolute" }}
            />

            <m.img
                initial={{ opacity: 0, scale: 1/*, y: 1000*/, filter: "blur(10px)",}}
                animate={{
                    opacity: 0.2,
                    scale: 1/*, y: 0*/,
                    filter: "blur(0px)",
                }}
                transition={{
                    delay: 0.0,
                    duration: 0.5,
                }}
                exit={{ opacity: 0, filter: "blur(5px)", }}
                component="img"
                style={{ width: "100vw", height: "100vh", }}
                alt="Ahegao."
                src={Ahegao}
            />

            <div className={classes.otro}>
                <div className="App" style={{ overflowY: "hidden" }}>
                    <header className="">

                        {/* ESTE ES EL QUE QUIERO DEJAR */}
                        <Box //Header Icono Home
                            id='HomeTopBar' justifyContent="center" display='flex'
                            style={{
                                margin: 0,
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)"
                            }} >
                            <m.a
                                initial={{ scale: 0/*, y: 1000*/ }}
                                animate={{
                                    borderRadius: "50%",
                                    scale: 3,/*, y: 0*/
/*                                    borderRadius: ["0%", "0%", "50%", "50%", "0%"]*/
                                }}
                                transition={{
                                    delay: 0.0,
                                    duration: 0.5,/*
                                    times: [0, 0.5, 1],
                                    repeat: Infinity*/
                                }}
                                exit={{ scale: 0 }}
                                href="/inicio" target="_self" rel="noreferrer">
                                <Box
                                    component="img"
                                    sx={{
                                        height: "auto",
                                        width: "10vw",
                                        position: "absolute",
                                        left: "50%",
                                        top: "50%",
                                        transform: "translate(-50%, -50%)",
                                        zIndex: 100000,
                                        borderRadius: "10%",
                                    }}
                                    alt="KannaLogo."
                                    src={KannaLogo}
                                />
                            </m.a>
                        </Box>

                    </header>
                </div>
            </div>
        </m.div>
    );
}

export default Carga;