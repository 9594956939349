import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Contacto from '../Screens/Contacto'
import Inicio from '../Screens/Inicio'
import Curriculum from '../Screens/Curriculum'
import Login from '../Screens/Login'
import Menu from '../Screens/Menu'
import Ubicacion from '../Screens/Ubicacion'
import Nosotros from '../Screens/Nosotros'
import Redes from '../Screens/Redes'
import Comentarios from '../Screens/Comentarios'
import Otros from '../Screens/Otros'

//Inicio Kannasenpaii
import Carga from '../Screens/Carga'
import InicioKanna from '../Screens/InicioKanna'
import Twitch from '../Screens/Twitch'
import TwitchGPT from '../Screens/TwitchGPT'
import Otro from '../Screens/Otro'
//Fin Kannasenpaii

//Inicio AlphabetMX
import InicioAlphabetMX from '../Screens/InicioAlphabetMX'
import Bolsas from '../Screens/Bolsas'
//Fin AlphabetMX

import Tools from '../Screens/Tools'
import Discord from '../Screens/Discord'

//Inicio AWS S3 Archivos
import Archivos from '../Screens/S3/Archivos';
//Fin AWS S3 Archivos

//Inicio QR
import QR from "../Screens/QR"
//Fin QR


const AppRoutes = ({ isMobileMode, isPCDrawerOpen, setPCDrawerOpen }) => {
    return (
        <div>
            <Routes>
                <Route path="/" element={<Inicio />} />
                <Route exact path="/inicio" element={<Inicio />} />
                <Route exact path="/contacto" element={<Contacto />} />
                <Route exact path="/curriculum" element={<Curriculum />} />
                <Route exact path="/login" element={<Login />} />
                <Route exact path="/menu" element={<Menu />} />
                <Route exact path="/ubicacion" element={<Ubicacion />} />
                <Route exact path="/nosotros" element={<Nosotros />} />
                <Route exact path="/redes" element={<Redes />} />
                <Route exact path="/comentarios" element={<Comentarios />} />
                <Route exact path="/otros" element={<Otros />} />

                {/*                <Route path="/" exact element={<Carga /> />} /> */} {/* Este define la primera pagina a cargar */}
                {/* Inicio Kannasenpaii */}
                <Route exact path="/iniciokanna" element={<InicioKanna />} />
                <Route exact path="/twitch" element={<Twitch />} />
                <Route exact path="/twitchgpt" element={<TwitchGPT />} />
                <Route exact path="/otro" element={<Otro />} />
                <Route exact path="/carga" element={<Carga />} />
                {/* Fin Kannasenpaii */}

                {/* Inicio AlphabetMX */}
                <Route exact path="/inicioalphabetmx" element={<InicioAlphabetMX />} />
                <Route exact path="/bolsas" element={<Bolsas />} />
                {/* Fin AlphabetMX */}

                <Route exact path="/discord" element={<Discord />} />

                {/* */}

                <Route exact path="/tools" element={<Tools />} />

                {/* Fin AlphabetMX */}
                <Route exact path="/archivos" element={<Archivos isMobileMode={isMobileMode} isPCDrawerOpen={isPCDrawerOpen} setPCDrawerOpen={setPCDrawerOpen} />} />
                {/* Fin AlphabetMX */}

                { /* Inicio QR */}
                <Route exact path="/qr" element={<QR />} />
                { /* Fin QR */}

            </Routes>
        </div>
    )
}

export default AppRoutes
