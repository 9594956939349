import React from "react";
import "./styles.css";

function Comentarios() {

  return (
    <div>
      <div>
        <div className="App">
          <header className="App-header">

            <br />

            Comentarios usando Google AppScript

            <h1 id="localitySelect">Localidad no se que</h1>

            <br />

          </header>
        </div>
      </div>
    </div>
  );
}

export default Comentarios;