import React, { useState, useEffect, useRef } from 'react';
import QRCodeStyling from 'qr-code-styling';

const QR = () => {
  const [qrProperties, setQRProperties] = useState({
    size: 300, // Utilizamos un solo campo para el tamaño (será tanto el ancho como el alto)
    type: 'image',
    data: 'https://www.example.com',
    image: '',
    margin: 5,
    qrOptions: {
      errorCorrectionLevel: 'Q',
      margin: 5,
      scale: 1,
      color: {
        dark: '#000000',
        light: '#ffffff',
      },
    },
    // Puedes agregar más propiedades aquí según sea necesario
    textOptions: {
      text: '',
    },
    urlOptions: {
      url: '',
    },
    emailOptions: {
      email: '',
      subject: '',
      body: '',
    },
    callOptions: {
      phoneNumber: '',
    },
    smsOptions: {
      phoneNumber: '',
      message: '',
    },
    wifiOptions: {
      ssid: '',
      password: '',
      encryption: 'WPA/WPA2',
    },
    vcardOptions: {
      firstName: '',
      lastName: '',
      organization: '',
      phone: '',
      email: '',
    },
  });

  const qrCodeContainerRef = useRef(null);
  const qrCodeInstanceRef = useRef(null);

  useEffect(() => {
    if (!qrCodeInstanceRef.current) {
      qrCodeInstanceRef.current = new QRCodeStyling(qrProperties);
      qrCodeInstanceRef.current.append(qrCodeContainerRef.current);
    } else {
      qrCodeInstanceRef.current.update(qrProperties);
    }

    return () => {
      // No necesitas destruir la instancia aquí
    };
  }, [qrProperties]);

  const handleDownloadClick = () => {
    // Descargar el QR al hacer clic en el botón
    qrCodeInstanceRef.current.download();
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    // Asegurarse de que el valor no baje de 29
    const newValue = name === 'size' ? Math.max(39, parseInt(value, 10)) : value;

    // Dividir el valor de size en width y height
    const newSize = parseInt(newValue, 10);
    setQRProperties((prevProperties) => ({
      ...prevProperties,
       [name]: name === 'width' || name === 'height' ? newSize : newValue,
      width: newSize,
      height: newSize,
    }));
  };

  const handleTypeChange = (event) => {
    const { value } = event.target;

    setQRProperties((prevProperties) => ({
      ...prevProperties,
      type: value,
    }));
  };

  const renderAdditionalOptionsForm = () => {
    switch (qrProperties.type) {
      case 'text':
        return (
          <label>
            Texto:
            <input
              type="text"
              name="textOptions.text"
              value={qrProperties.textOptions.text}
              onChange={handleInputChange}
            />
          </label>
        );

      case 'url':
        return (
          <label>
            URL:
            <input
              type="text"
              name="urlOptions.url"
              value={qrProperties.urlOptions.url}
              onChange={handleInputChange}
            />
          </label>
        );

      case 'email':
        return (
          <div>
            <label>
              Email:
              <input
                type="text"
                name="emailOptions.email"
                value={qrProperties.emailOptions.email}
                onChange={handleInputChange}
              />
            </label>
            <br />
            <label>
              Asunto:
              <input
                type="text"
                name="emailOptions.subject"
                value={qrProperties.emailOptions.subject}
                onChange={handleInputChange}
              />
            </label>
            <br />
            <label>
              Cuerpo:
              <input
                type="text"
                name="emailOptions.body"
                value={qrProperties.emailOptions.body}
                onChange={handleInputChange}
              />
            </label>
          </div>
        );

      // Agrega más casos según sea necesario para otros tipos
      default:
        return null;
    }
  };

  return (
    <div>
      <form>
        <label>
          Tamaño:
          <input
            type="number"
            name="size"
            value={qrProperties.size}
            style={{ width: "70px" }}
            onChange={handleInputChange}
          />
        </label>
        <label>
          Tipo:
          <select name="type" value={qrProperties.type} onChange={handleTypeChange}>
            <option value="image">Imagen</option>
            <option value="text">Texto</option>
            <option value="url">URL</option>
            <option value="email">Email</option>
            {/* Agrega más opciones según sea necesario */}
          </select>
        </label>
        <label>
          Image URL:
          <input
            type="text"
            name="image"
            value={qrProperties.image}
            onChange={handleInputChange}
          />
        </label>

        <br />
        {renderAdditionalOptionsForm()}

      </form>

      <button onClick={handleDownloadClick}>Descargar QR</button>

      <div ref={qrCodeContainerRef}>
        {/* El QR se renderizará aquí */}
      </div>
    </div>
  );
};

export default QR;
