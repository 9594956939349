import React, { useState, useEffect } from "react";
import { makeStyles } from '@mui/styles';
import 'bootstrap/dist/css/bootstrap.min.css';
// eslint-disable-next-line
import { Container, Row, Col } from 'react-bootstrap';
// eslint-disable-next-line
import Box from '@mui/material/Box';
// eslint-disable-next-line
import IconButton from '@mui/material/IconButton';
// eslint-disable-next-line
import { Link, useNavigate } from 'react-router-dom';
// eslint-disable-next-line
import Tooltip from '@mui/material/Tooltip';
// eslint-disable-next-line
import Zoom from '@mui/material/Zoom';
import { motion as m } from "framer-motion";

// eslint-disable-next-line
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
// eslint-disable-next-line
import { ReactComponent as AlphabetMxSVG } from '../../assets/AlphabetMx5.svg';
import AlphabetMx1 from "../../assets/alphabetmx.png";
import AlphabetMx2 from "../../assets/AlphabetMx_Logo_SinManchasBlancas.png";
import AlphabetMx3 from "../../assets/AlphabetMx_Logo_SinManchasBlancas_Recortado.png";
import AlphabetMx4 from "../../assets/AlphabetMx_Logo_Original.png";

// eslint-disable-next-line
//import ochok from "../../assets/8k.png";

// eslint-disable-next-line
import Skeleton from '@mui/material/Skeleton';

import Imagen from '../Elements/Imagen';
// eslint-disable-next-line
import transitions from "@mui/system";

const config = {
    //rootMargin: "bottom right up left",
    rootMargin: "0px 0px 0px 0px",
    threshold: 1.0,
};

//const alturaHome = 100;
// eslint-disable-next-line
const alturaDrawerTop = 50;
//const AlturaPaddingDrawer = alturaDrawerTop / 2;
// eslint-disable-next-line
const TamanoIcono = 100;


const useStyles = makeStyles((theme) => ({
    otro: {
        height: 'auto'
    },
    Dentro: {
        width: "100px", height: "100px",
        position: "relative", float: "left",
        backgroundColor: "#000000",
        margin: "0px 5px",
        color: "#ffffff",
        fontWeight: "bold"
    },
    column: {
        float: "left",
        width: "33.33%",
        padding: "5px"
    },
    Container: {
        width: "100%"
    },
    Row: {
        marginRight: "0px"
    }
}))

function Inicio(props) {
    // eslint-disable-next-line
    const [imagenes, setImagenes] = useState([
        {
            titulo: 'Iguana',
            path: "" + AlphabetMx1 + ""
        },
        {
            titulo: 'Iguana',
            path: "" + AlphabetMx2 + ""
        },
        {
            titulo: 'Iguana',
            path: "" + AlphabetMx3 + ""
        },
        {
            titulo: 'Iguana',
            path: "" + AlphabetMx4 + ""
        },/*
        {
            titulo: 'Iguana',
            path: "" + ochok + ""
        },*/
        {
            titulo: 'Comida',
            path: 'https://images.pexels.com/photos/12815070/pexels-photo-12815070.jpeg?auto=compress&cs=tinysrgb&w=1600&lazy=load'
        },
        {
            titulo: 'Bici',
            path: 'https://images.pexels.com/photos/12894053/pexels-photo-12894053.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
        },
        {
            titulo: 'Bloor',
            path: 'https://images.pexels.com/photos/11824539/pexels-photo-11824539.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
        },
        {
            titulo: 'Tormenta',
            path: 'https://images.pexels.com/photos/15129222/pexels-photo-15129222.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
        },
        {
            titulo: 'Tormenta',
            path: 'https://images.pexels.com/photos/4946595/pexels-photo-4946595.jpeg?auto=compress&cs=tinysrgb&w=300&lazy=load'
        },
        {
            titulo: 'Tormenta',
            path: 'https://images.pexels.com/photos/5207636/pexels-photo-5207636.jpeg?auto=compress&cs=tinysrgb&w=300&lazy=load'
        },
        {
            titulo: 'Tormenta',
            path: 'https://images.pexels.com/photos/5326957/pexels-photo-5326957.jpeg?auto=compress&cs=tinysrgb&w=300&lazy=load'
        },
        {
            titulo: 'Tormenta',
            path: 'https://images.pexels.com/photos/16115229/pexels-photo-16115229.jpeg?auto=compress&cs=tinysrgb&w=300&lazy=load'
        },
        {
            titulo: 'Tormenta',
            path: 'https://images.pexels.com/photos/15943127/pexels-photo-15943127.jpeg?auto=compress&cs=tinysrgb&w=300&lazy=load'
        },
        {
            titulo: 'Tormenta',
            path: 'https://images.pexels.com/photos/5074828/pexels-photo-5074828.jpeg?auto=compress&cs=tinysrgb&w=300&lazy=load'
        },
        {
            titulo: 'Tormenta',
            path: 'https://images.pexels.com/photos/15682443/pexels-photo-15682443.jpeg?auto=compress&cs=tinysrgb&w=300&lazy=load'
        },
        {
            titulo: 'Tormenta',
            path: 'https://images.pexels.com/photos/14831985/pexels-photo-14831985.jpeg?auto=compress&cs=tinysrgb&w=300&lazy=load'
        },
        {
            titulo: 'Tormenta',
            path: 'https://images.pexels.com/photos/12901382/pexels-photo-12901382.jpeg?auto=compress&cs=tinysrgb&w=300&lazy=load'
        },
        {
            titulo: 'Tormenta',
            path: 'https://images.pexels.com/photos/15666848/pexels-photo-15666848.jpeg?auto=compress&cs=tinysrgb&w=300&lazy=load'
        },
        {
            titulo: 'Tormenta',
            path: 'https://images.pexels.com/photos/11446333/pexels-photo-11446333.jpeg?auto=compress&cs=tinysrgb&w=300&lazy=load'
        },
        {
            titulo: 'Tormenta',
            path: 'https://images.pexels.com/photos/5346060/pexels-photo-5346060.jpeg?auto=compress&cs=tinysrgb&w=300&lazy=load'
        },
        {
            titulo: 'Tormenta',
            path: 'https://images.pexels.com/photos/14653318/pexels-photo-14653318.jpeg?auto=compress&cs=tinysrgb&w=300&lazy=load'
        },
    ]);

    // eslint-disable-next-line
    const [loaded, setLoaded] = useState({});
    // eslint-disable-next-line
    const [imageStatus, setImageStatus] = useState({});

    // eslint-disable-next-line
    const navigate = useNavigate();

    const classes = useStyles();

    /** Array a H1 con un forEach */
    const studentDetails = ['Alex', 'Anik', 'Deven', 'Rathore'];
    const names = [];
    studentDetails.forEach((data) => {
        names.push(<h3 className='student_name'>{data}</h3>)
    })

    useEffect(() => {
        const myArray = [10, 20, 30, 40, 50];
        myArray.forEach((el) => {
            console.log(el)
        });

        let observer = new window.IntersectionObserver(function (entries, self) {
            console.log("Detectando...");

            setTimeout(() => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        loadImages(entry.target);
                        self.unobserve(entry.target);
                    }
                });
            }, 100);
        }, config);

        const imgs = document.querySelectorAll("[data-src]");
        imgs.forEach((img) => {
            observer.observe(img);
        });
        return () => {
            imgs.forEach((img) => {
                observer.unobserve(img);
            });
        };
    }, []);

    const loadImages = (image) => {
        const src = image.dataset.src;
        const img = new Image();
        img.onload = () => {
            setLoaded(prevState => ({ ...prevState, [src]: true }));
            setImageStatus(prevState => ({ ...prevState, [src]: true }));
        }
        img.src = src;
    };

    const tiempo = 1;
    const container = {
        inicial: {
            y: 0,
            opacity: 0,
            scale: 1
        },
        animar: {
            y: 0,
            opacity: 1,
            scale: 1,
            transition: {
                delay: tiempo,
                duration: tiempo,
                delayChildren: tiempo + 0.5, //Lo que tarda para empezar los children a mostrarse
                staggerChildren: 0.3 //Tiempo entre childrens
            }
        }
    };

    const item = {
        inicial: {
            y: 20,
            opacity: 0.0,
            scale: 0.0
        },
        animar: {
            y: 0,
            opacity: 1,
            scale: 1.0/*,
            transition:{
                duration:4
            }*/
        }
    };

    //    const text = "Texto animado con Framer Motion";
    const titulo1 = "ESCAPE THE ORDINARY • BE AUTHENTIC ✨";

    return (
        <m.div style={{/*overflowY:"scroll"*/ }}
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.75, ease: "easeOut" }}
            exit={{ opacity: 1 }}
        >
            <div className={classes.otro}>
                <div className="App" style={{ overflowY: "hidden", overflowX: "hidden" }}>
                    <header className="App-container" style={{ display: "flex", paddingTop: "4vh" }}>

                        <div> {/** Circulos Animados Infinitos */}
                            <m.div
                                style={{
                                    position: "absolute", top: "0%", left: "0%", transform: "translate(-50%, -50%)",
                                    margin: "0vh", width: "10vh", height: "10vh", backgroundColor: "red"
                                }}
                                animate={{
                                    scale: [0.5, 1, 1, 0.5, 0.5],
                                    rotate: [0, 0, 180, 180, 0],
                                    borderRadius: ["0%", "0%", "50%", "50%", "0%"]
                                }}
                                transition={{
                                    duration: 2,
                                    ease: "easeInOut",
                                    times: [0, 0.2, 0.5, 0.8, 1],
                                    repeat: Infinity,
                                    repeatDelay: 1
                                }}
                            />

                            <m.div
                                style={{
                                    position: "absolute", top: "0%", right: "0%", transform: "translate(-50%, -50%)",
                                    margin: "0vh", width: "10vh", height: "10vh", backgroundColor: "red"
                                }}
                                animate={{
                                    scale: [0.1, 1, 0.1],
                                    rotate: [0, 720, 0],
                                    borderRadius: ["0%", "50%", "0%"]
                                }}
                                transition={{
                                    duration: 1,
                                    ease: "easeInOut",
                                    times: [0, 0.5, 1],
                                    repeat: Infinity,
                                    repeatDelay: 0
                                }}
                            />
                        </div>

                        {/*<div style={{ display: 'flex', color: "red" }}>
                            {text.split("").map((letter, index) => (
                                <m.span
                                    key={index}
                                    style={{ display: 'inline-block' }}
                                    animate={{
                                        y: [0, index % 2 === 0 ? -5 : -5, 0],
                                        x: [0, index * 0.3, 0]
                                    }}
                                    transition={{
                                        duration: 2.8,
                                        repeat: Infinity,
                                        ease: 'easeInOut',
                                        delay: (index * 0.1) + 1 // Delay de 0.1 segundos por letra
                                    }}
                                >
                                    {letter}
                                </m.span>
                            ))}
                        </div>*/}

                        {/*                        <div style={{ color: "black" }}>
                            <m.p
                                animate={{
                                    y: [0, 20, 0],
                                }}
                                transition={{
                                    duration: 1.5,
                                    repeat: Infinity,
                                }}
                            >
                                Primer párrafo animado
                            </m.p>
                            <m.p
                                animate={{
                                    y: [0, -20, 0],
                                }}
                                transition={{
                                    duration: 1.5,
                                    repeat: Infinity,
                                }}
                            >
                                Segundo párrafo animado
                            </m.p>
                        </div>*/}

                        <style> @import url('https://fonts.googleapis.com/css2?family=Alkatra&display=swap'); </style>
                        <div style={{ height: "90vh", width: "100vw", backgroundColor: "white" }} >

                            <m.div variants={container} initial="inicial" animate="animar" style={{ color: "black", fontFamily: "'Alkatra', cursive" }}>

                                <m.h1 variants={item}>
                                    {titulo1.split("").map((letter, index) => (
                                        <m.span
                                            key={index}
                                            style={{ display: 'inline-block' }}
                                            animate={{
                                                y: [0, index % 2 === 0 ? -5 : -5, 0],
                                                x: [0, index * 0.3, 0]
                                            }}
                                            transition={{
                                                duration: 2.8,
                                                repeat: Infinity,
                                                ease: 'easeInOut',
                                                delay: (index * 0.1) + 1 // Delay de 0.1 segundos por letra
                                            }}
                                        >
                                            {letter}
                                        </m.span>
                                    ))}
                                </m.h1>

                                <m.h1 variants={item} > Personaliza a tu estilo </m.h1>

                                <m.h1 variants={item} > Envíos nacionales ✈️ </m.h1>

                            </m.div>

                        </div>

                        <div className="row" style={{ marginBottom: "10px", width: "100%" }}>
                            {imagenes.map((imagen) => {
                                console.log(imagen.path);
                                return (
                                    <Imagen
                                        titulo={imagen.titulo}
                                        path={imagen.path}
                                    />
                                );
                            })}
                        </div>

                        <div className="Divisor" />

                    </header>
                </div>
            </div >
        </m.div >
    );
}

export default Inicio;