import React from "react";
import { makeStyles } from '@mui/styles';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
// eslint-disable-next-line
import { Link, useNavigate } from 'react-router-dom';
import { motion as m } from "framer-motion";

import { useInView } from "react-intersection-observer";

//const alturaHome = 100;
// eslint-disable-next-line
const alturaDrawerTop = 50;
//const AlturaPaddingDrawer = alturaDrawerTop / 2;
// eslint-disable-next-line
const TamanoIcono = 100;


const useStyles = makeStyles((theme) => ({
    otro: {
        height: 'auto'
    },
    Dentro: {
        width: "100px", height: "100px",
        position: "relative", float: "left",
        backgroundColor: "#000000",
        margin: "0px 5px",
        color: "#ffffff",
        fontWeight: "bold"
    },
    column: {
        float: "left",
        width: "33.33%",
        padding: "5px"
    },
    Container: {
        width: "100%"
    },
    Row: {
        marginRight: "0px"
    }
}))

function Otro() {
    const { ref, inView } = useInView({
        threshold: 1
      });

    const navigate = useNavigate();
    // Inicio 
    const classes = useStyles();

    //document.getElementById("HomeTopBar").style.display = "block";

    return (
        <m.div
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.75, ease: "easeOut" }}
            exit={{ opacity: 1 }}
        >
            <div className={classes.otro}>
                <div className="App" style={{ overflowY: "hidden" }}>
                    <header className="App-container">

                        <h1 style={{ color: "black" }}>Bols</h1>
                        <button onClick={() => navigate("/inicio")}>A Fuerzas</button>


                        <Container style={{ color: "#7932BC" }}>
                            <Row>
                                <m.div>
                                    <Col>1</Col>
                                    <Col>2</Col>
                                    <Col>3</Col>
                                </m.div>
                            </Row>
                            <Row>
                                <m.div>
                                    <Col>12</Col>
                                    <Col>23</Col>
                                    <Col>34</Col>
                                </m.div>
                            </Row>
                            <Col>
                                <m.div>
                                    <Row>4</Row>
                                    <Row>5</Row>
                                    <Row>6</Row>
                                </m.div>
                            </Col>
                        </Container>
                        <Container style={{ color: "#7932BC" }}>
                            <Row>
                                <m.div>
                                    <Col>1</Col>
                                    <Col>2</Col>
                                    <Col>3</Col>
                                </m.div>
                            </Row>
                            <Row>
                                <m.div>
                                    <Col>12</Col>
                                    <Col>23</Col>
                                    <Col>34</Col>
                                </m.div>
                            </Row>
                            <Col>
                                <m.div>
                                    <Row>4</Row>
                                    <Row>5</Row>
                                    <Row>6</Row>
                                </m.div>
                            </Col>
                        </Container>
                        <Container style={{ color: "#7932BC" }}>
                            <Row>
                                <m.div>
                                    <Col>1</Col>
                                    <Col>2</Col>
                                    <Col>3</Col>
                                </m.div>
                            </Row>
                            <Row>
                                <m.div>
                                    <Col>12</Col>
                                    <Col>23</Col>
                                    <Col>34</Col>
                                </m.div>
                            </Row>
                            <Col>
                                <m.div>
                                    <Row>4</Row>
                                    <Row>5</Row>
                                    <Row>6</Row>
                                </m.div>
                            </Col>
                        </Container>
                        <Container ref={ref} style={{ color: "green", fontSize:"1vw" }}>
                            <Row>
                                <m.div>
                                    <Col>{inView.toString()} 1</Col>
                                    <Col>2</Col>
                                    <Col>3</Col>
                                </m.div>
                            </Row>
                            <Row>
                                <m.div>
                                    <Col>12</Col>
                                    <Col>23</Col>
                                    <Col>34</Col>
                                </m.div>
                            </Row>
                            <Col>
                                <m.div>
                                    <Row>4</Row>
                                    <Row>5</Row>
                                    <Row>6</Row>
                                </m.div>
                            </Col>
                        </Container>

                    </header>
                </div>
            </div>
        </m.div>
    );
}

export default Otro;