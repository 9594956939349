import './App.css';
import Layout from './components/Layout';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRoutes from './components/Shared/AppRoutes';
import useLocalStorage from 'use-local-storage';
import SettingsIcon from '@mui/icons-material/Settings';
import ContrastIcon from '@mui/icons-material/Contrast';
//import MetaTags from 'react-meta-tags';
import { Container, Row, Col } from 'react-bootstrap';
import IconButton from '@mui/material/IconButton';
import Backdrop from '@mui/material/Backdrop';
import React, { useState, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
//import Drawer from "./components/Layout/Drawer"

const alturaDrawerTop = 7.50;
const restaAltura = 100 - alturaDrawerTop;
const AlturaPaddingDrawer = alturaDrawerTop / 2; //Ésta constante debe ser la mitad que la constante alturaDrawerTop


function App() {
  const [appIsMobileMode, setAppIsMobileMode] = useState(() => {
    const storedMode = JSON.parse(localStorage.getItem('isMobileMode'));
    return storedMode !== null ? storedMode : false;
  });

  const handleToggleMode = () => {
    setAppIsMobileMode(prevMode => !prevMode);
  };

  useEffect(() => {
    localStorage.setItem('isMobileMode', JSON.stringify(appIsMobileMode));
  }, [appIsMobileMode]);


  const defaultDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
  const [theme, setTheme] = useLocalStorage('theme', defaultDark ? 'dark' : 'light');

  const switchTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
  }

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  const Contraste =
    <IconButton onClick={switchTheme} style={{ height: `${alturaDrawerTop}vh`, paddingTop: `${AlturaPaddingDrawer}vh`, paddingBottom: `${AlturaPaddingDrawer}vh` /*position: "fixed", right: "1%", bottom: "95%",*/ /*zIndex: 10000,*/ /*padding: 0,*/ /*border: "none", background: "none", height: `${alturaDrawerTop}vh`*/ }}>
      {/*Cambiar a {theme === 'light' ? 'Oscuro' : 'Luz'}*/}
      <ContrastIcon fontSize="inherit" style={{ height: "4.5vh", width: "6.5vw" }} sx={{ color: "var(--text-primary)", height: `${alturaDrawerTop}vh` }} />
    </IconButton>;

  const Dispositivo =
    <button onClick={() => handleToggleMode(!appIsMobileMode)}>
      {appIsMobileMode ? 'Cambiar a Modo PC' : 'Cambiar a Modo Teléfono'}
    </button>;

  const Ajustes =
    <IconButton onClick={handleToggle} style={{ height: `${alturaDrawerTop}vh`, paddingTop: `${AlturaPaddingDrawer}vh`, paddingBottom: `${AlturaPaddingDrawer}vh` }} >
      <SettingsIcon fontSize="inherit" style={{ height: "7.5vh", width: "12vw" }} sx={{ color: "var(--text-primary)"/**pink[500] */, height: `${alturaDrawerTop}vh` }} />
    </IconButton>;

  const AjustesAbierto =
    <Backdrop
      sx={{ pointerEvents: "all", color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 10000 }}
      open={open}
    >
      {open &&
        <>
          <div onClick={handleClose} style={{ backgroundColor: "#00000040", width: "100%", height: "100%" }}></div>
          <div className='row' style={{ backgroundColor: "var(--background)", width: "60%", height: `${restaAltura}vh`, position: "fixed", right: "0%", top: `${alturaDrawerTop}vh`, borderStyle: "ridge", borderWidth: "4px", borderColor: "var(--text-primary)" }}>
            <Tooltip style={{ display: "flex" }} title="Cerrar" arrow>
              <IconButton onClick={handleToggle} style={{ position: "absolute", height: `${alturaDrawerTop}vh`, paddingTop: `${AlturaPaddingDrawer}vh`, paddingBottom: `${AlturaPaddingDrawer}vh` }} >
                <CloseIcon fontSize="inherit" style={{ height: "4.5vh", width: "6.5vw" }} sx={{ color: "var(--text-primary)"/**pink[500] */, height: `${alturaDrawerTop}vh` }} />
              </IconButton>
            </Tooltip>

            <div className='col-12' style={{ display: "flex", marginTop: "7.5vh" }} >
              <div className='col-6' style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                {Contraste}
              </div>

              <div className='col-6' style={{ display: "flex" }}>
                {Dispositivo}
              </div>
            </div>
            <div className='col-12' style={{ display: "flex" }} >
              <div className='col-6' style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                {Contraste}
              </div>

              <div className='col-6' style={{ display: "flex" }}>
                {Dispositivo}
              </div>
            </div>

          </div>
        </>
      }
    </Backdrop>;

  const Columnas =
    <div /*style={{ height: `${alturaDrawerTop}vh`, minHeight: `${alturaDrawerTop}vh!important`*, backgroundColor:"#ff000040"* }}*/>
      <Container className='cancelar-max-width' style={{ pointerEvents: "none", position: "fixed", zIndex: 10000, paddingLeft: 0, paddingRight: 0, marginLeft: 0, marginRight: 0, width: "100%" }}>
        <Row style={{ pointerEvents: "none" }}>
          <Col className='col-lg-2 col-md-2 col-sm-2 col-xs-2' style={{ display: "flex", justifyContent: "center" }}></Col>
          <Col className='col-lg-8 col-md-8 col-sm-8 col-xs-8' style={{ display: "flex", justifyContent: "center", visibility: "hidden", zIndex: -1, pointerEvents: "none" }}></Col>
          <Col className='col-lg-2 col-md-2 col-sm-2 col-xs-2 /*obj-sm-disapear*/' style={{ visibility: "visible", pointerEvents: "all" }}>{Ajustes}</Col>
        </Row>
      </Container>
    </div>;
  const [isPCDrawerOpen, setPCDrawerOpen] = useState(true);
  return (
    <Router>
      {/*      <MetaTags>
        <meta name="viewport" content="width=1000" />
      </MetaTags>*/}
      <div className="App" data-theme={theme}>

        {/*<Drawer isMobileMode={appIsMobileMode} onToggleMode={handleToggleMode} />*/}

        {Columnas}

        {AjustesAbierto}

        <Layout isMobileMode={appIsMobileMode} onToggleMode={handleToggleMode} isPCDrawerOpen={isPCDrawerOpen} setPCDrawerOpen={setPCDrawerOpen}  > {/* Pc y Movil */}
          <AppRoutes isMobileMode={appIsMobileMode} isPCDrawerOpen={isPCDrawerOpen} setPCDrawerOpen={setPCDrawerOpen}/>
        </Layout>
      </div>
    </Router>
  );
}

export default App;
