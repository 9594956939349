import React from 'react';
import { makeStyles } from '@mui/styles';
//import logo from '../../logo.svg';
/*import ReactJSsvg from '../../assets/ReactLogoSVG.svg';
import NETsvg from '../../assets/NETlogoSVG.svg';
import NET from '../../assets/NETlogo.png';*/
//import Typical from 'react-typical';
//import FancyText from 'react-fancytext'
//import { Typography, Container, Box } from '@mui/material';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { useCallback } from "react";
import Particles from "react-particles";
import { loadFull } from "tsparticles";

import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import { polygonPathName, loadPolygonPath } from "tsparticles-path-polygon";

const theme = createTheme({
    status: {
        danger: '#e53e3e',
    },
    palette: {
        primary: {
            main: '#0971f1',
            darker: '#053e85',
        },
        otro: {
            main: '#ff0000',
            darker: '#ff0000',
        },
        neutral: {
            main: '#64748B',
            contrastText: '#fff',
        },
    },
});

const useStyles = makeStyles((theme) => ({
    otro: {
        height: 'auto'
    },
    Dentro: {
        width: "100px", height: "100px",
        position: "relative", float: "left",
        backgroundColor: "#ffffff",
        margin: "0px 5px",
        color: "#000000",
        fontWeight: "bold"
    },
    column: {
        float: "left",
        width: "33.33%",
        padding: "5px"
    }
}))

const Inicio = () => {
    const classes = useStyles();

    const particlesInit = useCallback(async engine => {
        //console.log(engine);
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(engine);
        await loadPolygonPath(engine);
    }, []);

    const particlesLoaded = useCallback(async container => {
        //await console.log(container);
    }, []);

    return (
        <div className={classes.otro}>
            <div className="App">
                <header className="App-header">

                    <div>{/*Esconder comentarios*/}
                        {/*<Box display="flex" width="100%" justifyContent="center" alignItems="center">

                        <Box width="20%">Item 1</Box>
                        <a href="https://emmanueltc.github.io/inicio/">
                            <img src={ReactJSsvg} className="App-logo" alt="logo" />
                        </a>

                        <Box width="40%">Item 2</Box>

                        <a href="https://emmanueltc.github.io/net/">
                            <img style={{ marginTop: "50px", width: "30vmin", height: "15vmin", margin: "7vmin 0px" }} id="slide" src={NETsvg} className="NET_logo" alt="NETlogo" />
                        </a>
                        <Box width="20%">Item 3</Box>
                    </Box>*/}
                    </div>

                    <div style={{ position: "fixed", width: "100%" }}>

                    </div>


                    <div className="PadreTS" style={{ zIndex: 0 }} /*style={{ position: "fixed", height: "500px", width: "500px" }}*/>

                        <Particles id="tsparticles2" style={{ position: "fixed", top: "6.6vh", left: "0", width: "10%", height: "10%" }}
                            init={particlesInit}
                            loaded={particlesLoaded}
                            options={{
                                fullScreen: { enable: false },
                                "fpsLimit": 140,
                                particles: {
                                    number: {
                                        density: {
                                            enable: true,
                                            area: 800
                                        },
                                        value: 0
                                    },
                                    color: {
                                        value: "#787878",
                                        animation: {
                                            enable: false, //true para poner los colores Random o que cambien de color con el tiempo
                                            speed: 10
                                        }
                                    },
                                    shape: {
                                        type: "circle"
                                    },
                                    opacity: {
                                        value: 1
                                    },
                                    size: {
                                        value: 2
                                    },
                                    move: {
                                        attract: {
                                            enable: false,
                                            distance: 100,
                                            rotate: {
                                                x: 2000,
                                                y: 2000
                                            }
                                        },
                                        direction: "none",
                                        enable: true,
                                        outModes: {
                                            default: "destroy"
                                        },
                                        path: {
                                            clamp: false,
                                            enable: true,
                                            delay: {
                                                value: 0
                                            },
                                            generator: polygonPathName,
                                            options: {
                                                sides: 6,
                                                turnSteps: 30,
                                                angle: 30
                                            }
                                        },
                                        random: false,
                                        speed: 3,
                                        straight: false,
                                        trail: {
                                            fillColor: "rgba(0, 0, 0, 0)",
                                            strokeColor: "#ff0000",
                                            length: 40,
                                            enable: false
                                        }
                                    },

                                },
                                background: {
                                    color: "#000000000"
                                    /**
                                     * "color": "#000000000", //Color de fondo de particulas
                                    "image": "",
                                    "position": "50% 50%",
                                    "repeat": "no-repeat",
                                    "size": "cover"
                                     */
                                },
                                emitters: {
                                    direction: "none",
                                    rate: {
                                        quantity: 1,
                                        delay: 0.25
                                    },
                                    size: {
                                        width: 0,
                                        height: 0
                                    },
                                    position: {
                                        x: 50,
                                        y: 50
                                    }
                                },
                                "interactivity": {
                                    "detect_on": "window",
                                    "events": {
                                        "onhover": {
                                            "enable": true,
                                            "mode": "grab",
                                            "parallax": {
                                                "enable": true,
                                                "smooth": 10,
                                                "force": 60
                                            }
                                        },
                                        "onclick": {
                                            "enable": true,
                                            "mode": ["remove", "push"]
                                        },
                                        "resize": true
                                    },
                                    "modes": {
                                        "grab": {
                                            "distance": 300,
                                            "line_linked": {
                                                "opacity": 1,
                                                "color": "random"
                                            }
                                        },
                                        "bubble": {
                                            "distance": 400,
                                            "size": 40,
                                            "duration": 2,
                                            "opacity": 0.8
                                        },
                                        "repulse": {
                                            "distance": 200
                                        },
                                        "push": {
                                            "particles_nb": 20
                                        },
                                        "remove": {
                                            "particles_nb": 20
                                        }
                                    }
                                },
                                "retina_detect": true,
                            }}
                        />
                    </div>

                    {
                        //#region Particulas Normales 
                    }
                    {/*                    <div style={{ position: "fixed", height: "500px", width: "500px" }}>

                        <Particles id="tsparticles2" style={{ position: "absolute", top: "0", left: "0", width: "100%", height: "100%", zIndex: "111111", outline: "solid 0px orange", outlineOffset: "-1px", borderWidth:1, borderStyle:"solid",borderColor:"red" }}
                            init={particlesInit}
                            loaded={particlesLoaded}
                            options={{
                                fullScreen: { enable: false },
                                "fpsLimit": 30,
                                "particles": {
                                    "number": {
                                        "value": 100,
                                        "density": {
                                            "enable": true,
                                            "value_area": 2000
                                        }
                                    },
                                    "color": {
                                        "value": "#787878", //Color de los circulos
                                        "animation": {
                                            "enable": false,
                                            "speed": 20,
                                            "sync": true
                                        }
                                    },
                                    "shape": {
                                        "type": "circle"
                                    },
                                    "opacity": {
                                        "value": 0.5,
                                        "random": false,
                                        "anim": {
                                            "enable": false,
                                            "speed": 3,
                                            "opacity_min": 0.1,
                                            "sync": false
                                        }
                                    },
                                    "size": {
                                        "value": 4, //Tamaño de los circulos
                                        "random": true,
                                        "anim": {
                                            "enable": true,
                                            "speed": 20,
                                            "size_min": 0.1,
                                            "sync": false
                                        }
                                    },
                                    "line_linked": {
                                        "enable": true,
                                        "distance": 100,
                                        "color": "random",
                                        "opacity": 0.4,
                                        "width": 1,
                                        "triangles": {
                                            "enable": true,
                                            "color": "#787878", //Color de los triangulos
                                            "opacity": 0.5
                                        }
                                    },
                                    "move": {
                                        "enable": true,
                                        "speed": 6,
                                        "direction": "none",
                                        "random": false,
                                        "straight": false,
                                        trail: {
                                            fillColor: "#00000000",
                                            length: 40,
                                            enable: true
                                        },
                                        "out_mode": "out",
                                        "attract": {
                                            "enable": false,
                                            "rotateX": 600,
                                            "rotateY": 1200
                                        }
                                    }
                                },
                                "interactivity": {
                                    "detect_on": "window",
                                    "events": {
                                        "onhover": {
                                            "enable": true,
                                            "mode": "grab",
                                            "parallax": {
                                                "enable": true,
                                                "smooth": 10,
                                                "force": 60
                                            }
                                        },
                                        "onclick": {
                                            "enable": false,
                                            "mode": ["remove", "push"]
                                        },
                                        "resize": true
                                    },
                                    "modes": {
                                        "grab": {
                                            "distance": 300,
                                            "line_linked": {
                                                "opacity": 1
                                            }
                                        },
                                        "bubble": {
                                            "distance": 400,
                                            "size": 40,
                                            "duration": 2,
                                            "opacity": 0.8
                                        },
                                        "repulse": {
                                            "distance": 200
                                        },
                                        "push": {
                                            "particles_nb": 20
                                        },
                                        "remove": {
                                            "particles_nb": 20
                                        }
                                    }
                                },
                                "retina_detect": true,
                                "background": {
                                    "color": "#000000000", //Color de fondo de particulas
                                    "image": "",
                                    "position": "50% 50%",
                                    "repeat": "no-repeat",
                                    "size": "cover"
                                }

                            }}
                        />

                    </div>*/}
                    {
                        //#endregion
                    }

                    <div style={{ zIndex: 0 }}>
                        <h2 style={{ zIndex: 1, fontFamily: "monospace", fontWeight: "bold" }}>Emmanuel Trujillo Castillo</h2>

                        <h4>"// eslint-disable-next-line"</h4>

                        {/*                   <FancyText>a</FancyText>*/}

                        <p style={{ fontFamily: 'monospace' }}>
                            Desarrollador / Developer
                            {/*<Typical
                            style={{ fontFamily: 'monospace' }}
                            loop={Infinity}
                            wrapper="b"
                            steps={[
                                '',
                                2000,
                                'De',
                                200,
                                'Desa',
                                200,
                                'Desarro',
                                200,
                                'Desarrolla',
                                200,
                                'Desarrollador',
                                3000,
                                'Desarrolla',
                                100,
                                'Desarro',
                                100,
                                'Desa',
                                100,
                                'De',
                                100,
                                'Deve',
                                200,
                                'Develo',
                                200,
                                'Developer',
                                3000,
                                'Develo',
                                100,
                                'Deve',
                                100,
                                'De',
                                100
                                
                            ]}
                        />*/}
                        </p>

                        <br />

                        <Container style={{ display: "none" }}>
                            <Row>
                                <Col className='col-md-4 col-sm-6 col-xs-12'>1/3</Col>
                                <Col className='col-md-4 col-sm-6 col-xs-12'>2/3</Col>
                                <Col className='col-md-4 col-sm-6 col-xs-12'>3/3</Col>
                            </Row>
                        </Container>

                        <br />

                        <ThemeProvider theme={theme}>
                            <Button style={{ display: "none" }} variant="outlined">secondary</Button>
                            <Button style={{ display: "none" }} variant="outlined" color='otro'>otro</Button>
                            <Button style={{ display: "none" }} variant="outlined" color="neutral">neutral</Button>
                        </ThemeProvider>

                        <ThemeProvider theme={theme}>
                            <Button style={{ display: "none" }} variant="contained" color="otro" component={Link} to="/uno"
                                sx={{ mb: 1, bgcolor: "var(--text-primary)", width: 350, height: 35, color: "var(--background)" }}>UNO</Button>
                        </ThemeProvider>
                        <Stack style={{ display: "none" }} spacing={2} direction="row">
                            <Button variant="contained" color="primary" component={Link} to="/nueve"
                                sx={{ mb: 1, bgcolor: "var(--text-primary)", width: 350, height: 35, color: "var(--background)" }}>NUEVE</Button>
                            <Button variant="contained" color="primary" component={Link} to="/diez"
                                sx={{ mb: 1, bgcolor: "var(--text-primary)", width: 350, height: 35, color: "var(--background)" }}>DIEZ</Button>
                        </Stack>
                    </div>
                    <br />

                    {/*<a className="App-link" href="https://reactjs.org" target="_blank" rel="noopener noreferrer" >
                        React.JS
                    </a>*/}
                </header>
            </div>
        </div>
    )

    //---------------------------------------------------------------------------
    /*   function EmmanuelTC_Offline() {
                        document.getElementById('EmmanuelTC').style.display = 'block';
                    document.getElementById('EmmanuelTC_Chat').style.display = 'block';
                    document.getElementById('LiveEmmanuelTC').style.display = 'block';
       }*/
}

export default Inicio
