import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Drawer.css'; // Agrega estilos adicionales según tus necesidades
import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import MuiList from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import BuildIcon from '@mui/icons-material/Build';
import { Link } from 'react-router-dom';
import { ReactComponent as TwitchIcon } from '../../assets/svg/Twitch.svg'//'../../assets/svg/Twitch.svg';

import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu'; // Icono para "Mostrar"
import CloseIcon from '@mui/icons-material/MenuOpen'; // Icono para "Ocultar"

import HomeIcon from '@mui/icons-material/Home';

import QrCodeIcon from '@mui/icons-material/QrCode2';


const MobileDrawer = ({ isOpen, onClose, isMobileMode }) => {
    const StyledList = styled(MuiList)({ //Colores de la Barra Izquierda al estar por y al seleccionar
        // selected and (selected + hover) states
        '&& .Mui-selected, && .Mui-selected:hover': { //Color del seleccionado de la Barra Izquierda
            backgroundColor: 'var(--text-primary)',
            transition: "background-color 0.5s ease",
            '&, & .MuiTypography-root, & svg, g': {
                color: 'var(--background)',
                fill: "var(--background)",
                transition: "background-color 0.5s ease",
            },
        },
        ".MuiDivider-root": {
            height: "1px",
            backgroundColor: "var(--text-primary)"
        },
        ".MuiSvgIcon-root": {
            //height: { xs:"4vh", md:"1vh", lg:"10vh"},
            width: "4vw"
        },
        ".MuiList-root": {
            padding: "0px",
        },
        // hover states
        '& .MuiListItemButton-root:hover': { //Color del que se seleccionara de la Barra Izquierda
            backgroundColor: 'orange',
            transition: "background-color 0.5s ease",
            '&, & .MuiTypography-root': {
                color: 'yellow',
                transition: "background-color 0.5s ease",
            },
        },
        ".MuiTypography-root": {
            //height: { xs:"4vh", md:"1vh", lg:"10vh"},
            //width: "4vw"
            fontSize: "1.11vw"
        },
    });

    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const handleListItemClick = (index) => {
        setSelectedIndex(index);
    };

    return (
        <div className={`mobile-drawer ${isOpen ? 'open' : ''}`}
            style={{
                backgroundColor: isMobileMode ? 'var(--background)' : 'var(--background)',
                width: isMobileMode ? '100vw' : '25.55vw',
                borderWidth: isMobileMode ? "0px 0px 1px 0px" : "0px 1px 1px 0px",
                borderStyle: "solid",
                borderColor: "var(--text-primary)",
                position: "absolute",
                transition: 'transform 0.555s',
                transform: isOpen ? isMobileMode ? 'translateY(10.55vh)' : "translateY(8.55vh)" : 'translateY(-50vh)',
                opacity: isOpen ? "100%" : "0%",
                left: 0
            }}>
            <div className='row' style={{ margin: 0 }}>

                <StyledList sx={{ paddingTop: "0px", paddingBottom: "0px" }} >
                    <ListItem key="Inicio" disablePadding component={Link} to="/inicio" style={{ height: "6.33vh" }}>
                        <ListItemButton sx={{ justifyContent: "center" }} selected={selectedIndex === 1} onClick={() => handleListItemClick(1)}>
                            <ListItemIcon style={{ justifyContent: "center" }} sx={{ jusifyContent: "center", px: "2vw", width: { xs: "10vw", md: "4vh" } }}>
                                <HomeIcon style={{ width: "10vh" }} sx={{ fontSize: { xs: "4.5vh", md: "4.5vh", lg: "4.5vh" }, color: "var(--text-primary)" }} />
                            </ListItemIcon>
                            {isMobileMode ? <div className='NavLetra' >Inicio</div> : <ListItemText primary="Inicio" fontSize="medium" sx={{ color: "var(--text-primary)", opacity: 1 }} />}
                        </ListItemButton>
                    </ListItem>
                    <Divider />
                    <ListItem key="Tools" disablePadding component={Link} to="/tools" style={{ height: "6.33vh" }}>
                        <ListItemButton sx={{ justifyContent: "center" }} selected={selectedIndex === 2} onClick={() => handleListItemClick(2)}>
                            <ListItemIcon style={{ justifyContent: "center" }} sx={{ jusifyContent: "center", px: "2vw", width: { xs: "10vw", md: "4vh" } }}>
                                <BuildIcon style={{ width: "10vh" }} sx={{ fontSize: { xs: "4.5vh", md: "4.5vh", lg: "4.5vh" }, color: "var(--text-primary)" }} />
                            </ListItemIcon>
                            {isMobileMode ? <div className='NavLetra' >Tools</div> : <ListItemText primary="Tools" fontSize="medium" sx={{ color: "var(--text-primary)", opacity: 1 }} />}
                        </ListItemButton>
                    </ListItem>
                    <Divider />
                    <ListItem key="TwitchGPT" disablePadding component={Link} to="/twitchgpt" style={{ height: "6.33vh" }}>
                        <ListItemButton sx={{ justifyContent: "center" }} selected={selectedIndex === 3} onClick={() => handleListItemClick(3)}>
                            <ListItemIcon style={{ justifyContent: "center" }} sx={{ jusifyContent: "center", px: "0.5vw", width: { xs: "10vw", md: "4vw" } }}>

                                <TwitchIcon height="4.5vh" fill='var(--text-primary)' />

                            </ListItemIcon>
                            {isMobileMode ? <div className='NavLetra' >TwitchGPT</div> : <ListItemText primary="TwitchGPT" fontSize="medium" sx={{ color: "var(--text-primary)", opacity: 1 }} />}
                        </ListItemButton>
                    </ListItem>
                    <Divider />
                    <ListItem key="QR" disablePadding component={Link} to="/qr" style={{ height: "6.33vh" }}>
                        <ListItemButton sx={{ justifyContent: "center" }} selected={selectedIndex === 4} onClick={() => handleListItemClick(4)}>
                            <ListItemIcon style={{ justifyContent: "center" }} sx={{ jusifyContent: "center", px: "0.5vw", width: { xs: "10vw", md: "4vw" } }}>

                                <QrCodeIcon style={{ width: "10vh" }} sx={{ fontSize: { xs: "4.5vh", md: "4.5vh", lg: "4.5vh" }, color: "var(--text-primary)" }} />

                            </ListItemIcon>
                            {isMobileMode ? <div className='NavLetra' >QR</div> : <ListItemText primary="QR" fontSize="medium" sx={{ color: "var(--text-primary)", opacity: 1 }} />}
                        </ListItemButton>
                    </ListItem>
                </StyledList>

                {isMobileMode && (
                    <button onClick={onClose}>Cerrar</button>
                )}
            </div>

        </div>
    );
};

const Drawer = ({ isMobileMode, isPCDrawerOpen, setPCDrawerOpen }) => {
    const [isMobileDrawerOpen, setMobileDrawerOpen] = React.useState(false);
    //const [isPCDrawerOpen, setPCDrawerOpen] = React.useState(true);

    const toggleMobileDrawer = () => {
        setMobileDrawerOpen(!isMobileDrawerOpen);
    };

    const closeMobileDrawer = () => {
        setMobileDrawerOpen(false);
    };

    const togglePCDrawer = () => {
        // Agregamos un delay de 0.5 segundos antes de actualizar el estado
        //        setTimeout(() => {
        setPCDrawerOpen(!isPCDrawerOpen);
        //        }, 500);
    };


    return (
        <div id="Header" style={{ justifyContent: "center", display: 'flex', flexDirection: 'column', position: 'fixed', width: '100%', height: '7.5vh', backgroundColor: 'var(--background)', borderWidth: "0px 0px 1px 0px", borderStyle: "solid", borderColor: "var(--text-primary)", zIndex: 1000, alignItems: isMobileMode ? 'center' : 'flex-start' }}>
            {isMobileMode ? (
                <div>
                    <IconButton id="Cel-Header" onClick={toggleMobileDrawer} style={{ position: "relative", left: 0, zIndex: 1234, transition: 'left 0.1s' }}>
                        {isMobileDrawerOpen ? <CloseIcon style={{ height: "7.5vh", width: "12vw" }} sx={{ color: "var(--text-primary)" }} /> : <MenuIcon style={{ height: "7.5vh", width: "12vw" }} sx={{ color: "var(--text-primary)" }} />}
                    </IconButton>

                    <MobileDrawer isOpen={isMobileDrawerOpen} onClose={closeMobileDrawer} isMobileMode={isMobileMode} />
                </div>
            ) : (
                <div>
                    <IconButton onClick={togglePCDrawer} style={{ position: "relative", left: 0, zIndex: 1234, transition: 'left 0.1s' }}>
                        {isPCDrawerOpen ? <CloseIcon style={{ height: "7.5vh", width: "12vw" }} sx={{ color: "var(--text-primary)" }} /> : <MenuIcon style={{ height: "7.5vh", width: "12vw" }} sx={{ color: "var(--text-primary)" }} />}
                    </IconButton>

                    <div style={{ position: 'relative', marginLeft: isPCDrawerOpen ? '0' : '-100vw', /*marginTop: '7.5vh',*/ backgroundColor: 'orange', zIndex: 1000, transition: 'margin-left 0.555s' }}>
                        {/* PC Drawer */}
                        {isPCDrawerOpen && <MobileDrawer isOpen={isPCDrawerOpen} onClose={togglePCDrawer} isMobileMode={isMobileMode} />}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Drawer;
