import React from 'react'

const Contacto = () => {
    return (
        <div>
            <div style={{ height: "10px", backgroundColor: "#ffffff" }} />
            <h1><code>Contacto</code></h1>
        </div>
    )
}

export default Contacto
