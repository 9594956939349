// FileUploader.js
import React, { useCallback, useState, useRef, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import AWS from "./aws-config";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

const CameraControls = ({ videoRef, updateFileList }) => {
    const [selectedResolution, setSelectedResolution] = useState(null);
    const [isCameraActive, setIsCameraActive] = useState(false); // Nuevo estado
    // eslint-disable-next-line
    const [videoKey, setVideoKey] = useState(0); // Nueva clave para el video
    // eslint-disable-next-line
    const [isStoppingCamera, setIsStoppingCamera] = useState(false);
    const [previewImage, setPreviewImage] = useState(null);
    const [showConfirmation, setShowConfirmation] = useState(false); // Nuevo estado

    useEffect(() => {
        if (isCameraActive) {
            getMedia();
        }
    });

    const getMedia = async () => {
        try {
            if (!videoRef.current.srcObject) {
                const stream = await navigator.mediaDevices.getUserMedia({
                    video: {
                        width: { ideal: selectedResolution ? selectedResolution.width : undefined },
                        height: { ideal: selectedResolution ? selectedResolution.height : undefined },
                    },
                });

                videoRef.current.srcObject = stream;
            }
        } catch (error) {
            console.error('Error al obtener acceso a la cámara:', error);
        }
    };

    const toggleCamera = () => {
        setIsCameraActive(!isCameraActive);

        if (isCameraActive) {
            // Si la cámara está apagada, detener la transmisión de video
            stopCapture();
        } else {
            // Si la cámara está encendida, volver a obtener acceso y reanudar la transmisión
            getMedia();
        }
    };


    // Función para cambiar la resolución seleccionada
    const handleResolutionChange = async (resolution) => {
        //Si la camara está apagada la activará
        if (!isCameraActive) {
            setIsCameraActive(!isCameraActive);
        }
        setSelectedResolution(resolution);

        // Si la cámara está activa, detenerla y luego reiniciarla con la nueva resolución
        if (isCameraActive) {
            await stopCapture();

            // Agregar un evento onended para detectar cuando la cámara se ha apagado completamente
            videoRef.current.onended = () => {
                //setIsCameraActive(!isCameraActive);
                // El evento se dispara cuando la cámara se apaga por completo
                videoRef.current.onended = null; // Limpia el evento para evitar que se dispare nuevamente

                console.log("prueba");
                // Vuelve a encender la cámara con la nueva resolución
                toggleCamera();
            };
        }
    };


    useEffect(() => {
        // Ajustar el tamaño del video al 70% del ancho de la ventana visual
        const updateVideoSize = () => {
            const newWidth = window.innerWidth * 0.7;
            videoRef.current.style.width = `${newWidth}px`;
        };

        // Llamar a la función cuando cambie el tamaño de la ventana
        window.addEventListener("resize", updateVideoSize);

        // Llamar a la función al principio para establecer el tamaño inicial
        updateVideoSize();

        // Eliminar el evento cuando el componente se desmonte
        return () => {
            window.removeEventListener("resize", updateVideoSize);
        };
    }, [videoRef]);

    const stopCapture = async () => {
        setIsStoppingCamera(true);

        try {
            const stream = videoRef.current.srcObject;

            if (stream) {
                const videoTracks = stream.getVideoTracks();

                videoTracks.forEach((track) => track.stop());
                videoRef.current.srcObject = null;
            } else {
                console.warn('El objeto de origen de la cámara es nulo.');
            }
        } catch (error) {
            console.error('Error al detener la cámara:', error);
        } finally {
            setIsStoppingCamera(false);
        }
    };

    const capturePhoto = () => {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        const video = videoRef.current;

        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        context.drawImage(video, 0, 0, canvas.width, canvas.height);

        // Obtener la resolución seleccionada
        const resolution = selectedResolution
            ? `${selectedResolution.width}x${selectedResolution.height}`
            : '640x480';

        // Cambia el formato a PNG
        canvas.toBlob((blob) => {
            // Actualizar la vista previa con la imagen capturada
            setPreviewImage(URL.createObjectURL(blob));
            //            stopCapture();
            //uploadImage(blob, resolution);
            setShowConfirmation(true);
            setConfirmationData({ previewImageBlob: blob, resolution });
        }, "image/png");
    };

    const [confirmationData, setConfirmationData] = useState(null);

    const confirmUpload = async () => {
        if (confirmationData) {
            const { previewImageBlob, resolution } = confirmationData;
            await uploadImage(previewImageBlob, resolution);
            setShowConfirmation(false);
            setPreviewImage(null);
            setConfirmationData(null);
        }
    };

    const cancelUpload = () => {
        setShowConfirmation(false);
        setPreviewImage(null);
        setConfirmationData(null);
    };

    const uploadImage = async (imageBlob, resolution) => {
        const s3 = new AWS.S3();
        const fileName = `captured_${resolution}_${Date.now()}.jpg`; // Incluye la resolución en el nombre del archivo

        const params = {
            Bucket: "emmanueltc.db.tools",
            Key: fileName,
            Body: imageBlob,
            ContentType: "image/jpeg", // Agrega el tipo de contenido adecuado
        };

        try {
            const data = await s3.upload(params).promise();
            console.log("Archivo subido con éxito:", data);
            // Después de cargar el archivo con éxito, actualiza la lista de archivos
            updateFileList();
        } catch (error) {
            console.error("Error al subir archivo:", error);
        }
    };

    return (
        <div className="row" style={{ margin: 0 }}>
            <div className="col-12 text-center" style={{ fontSize: "4vw" }}>Cámara</div>
            <div className="row col-12">
                <div className="col-3 text-center" style={{ alignSelf: "center" }}>Resolución:</div>
                <select style={{ color: "var(--text-primary)", backgroundColor: "var(--background)" }} className="col-3" onChange={(e) => handleResolutionChange(JSON.parse(e.target.value))}>
                    <option value={JSON.stringify({ width: 4, height: 3 })}>4x3</option>
                    <option value={JSON.stringify({ width: 20, height: 15 })}>20x15</option>
                    <option value={JSON.stringify({ width: 40, height: 30 })}>40x30</option>
                    <option value={JSON.stringify({ width: 80, height: 60 })}>80x60</option>
                    <option value={JSON.stringify({ width: 160, height: 120 })}>160x120</option>
                    <option value={JSON.stringify({ width: 320, height: 240 })}>320x240</option>
                    <option value={JSON.stringify({ width: 640, height: 480 })}>640x480</option>
                    <option value={JSON.stringify({ width: 1280, height: 720 })}>1280x720</option>
                    <option value={JSON.stringify({ width: 1920, height: 1080 })}>1920x1080</option>
                    <option value={JSON.stringify({ width: 1920, height: 540 })}>1920x540</option>
                    <option value={JSON.stringify({ width: 2560, height: 1440 })}>2560x1440</option>
                    <option value={JSON.stringify({ width: 2880, height: 1620 })}>2880x1620</option>
                    <option value={JSON.stringify({ width: 3840, height: 2160 })}>3840x2160</option>
                    {/* Agrega más opciones según sea necesario */}
                </select>

                <button className="col-2" onClick={toggleCamera} style={{ fontSize: "3vw" }}>
                    <i className={`fa fa-camera${isCameraActive ? ' active' : ''}`}></i> {isCameraActive ? 'Apagar' : 'Encender'}
                </button>

                <button className="col-2" onClick={capturePhoto} style={{ fontSize: "3vw", display: `${isCameraActive ? 'block' : 'none'}` }}>
                    <i className="fa fa-cloud-upload"></i> Capturar
                </button>

                {/* Confirmación para subir la imagen */}
                {showConfirmation && (
                    <>
                        <button className="col-1" onClick={confirmUpload} style={{ fontSize: "3vw", color: "green" }}>
                            <FontAwesomeIcon icon={faCheck} /> {/* Icono de marca de verificación */}
                        </button>
                        <button className="col-1" onClick={cancelUpload} style={{ fontSize: "3vw", color: "red" }}>
                            <FontAwesomeIcon icon={faTimes} /> {/* Icono de X */}
                        </button>
                    </>
                )}

            </div>

            <div className="row col-6" style={{ maxWidth: "50vw", overflow: "hidden", textAlign: "center", alignSelf: "center" }}>
                {/* Elemento de video para la transmisión de la cámara */}
                <video
                    ref={videoRef}
                    key={videoKey} // Cambiar la clave para forzar la actualización
                    autoPlay={isCameraActive}
                    playsInline
                    style={{ width: "100%" }}
                />
            </div>

            <div className="col-6">
                {/* Vista previa de la imagen capturada */}
                {previewImage && (
                    <div className="row col-12 mx-auto" style={{ maxWidth: "50vw", overflow: "hidden", textAlign: "center", alignSelf: "center" }}>
                        <img src={previewImage} alt="Vista previa" style={{ width: "100%" }} />
                    </div>
                )}
            </div>
        </div>
    );
};

const FileUploader = ({ updateFileList }) => {
    const [selectedFile/*, setSelectedFile*/] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const videoRef = useRef();

    const onDrop = useCallback((files) => {
        const s3 = new AWS.S3();

        files.forEach((file) => {
            const params = {
                Bucket: "emmanueltc.db.tools",
                Key: file.name,
                Body: file,
            };

            s3.upload(params, (err, data) => {
                if (err) {
                    console.error(err);
                } else {
                    console.log("Archivo subido con éxito:", data);
                    // Después de cargar el archivo con éxito, actualiza la lista de archivos
                    updateFileList();
                }
            });
        });
    }, [updateFileList]);

    const handleUpload = () => {
        if (selectedFile) {
            const s3 = new AWS.S3();
            const params = {
                Bucket: "emmanueltc.db.tools",
                Key: selectedFile.name,
                Body: selectedFile,
            };

            const xhr = new XMLHttpRequest();

            xhr.upload.onprogress = (event) => {
                if (event.lengthComputable) {
                    const percentUploaded = (event.loaded / event.total) * 100;
                    setUploadProgress(percentUploaded);
                }
            };

            xhr.onload = () => {
                if (xhr.status === 200) {
                    console.log("Archivo subido con éxito.");
                    updateFileList();
                }
            };

            xhr.open("PUT", s3.getSignedUrl("putObject", params));
            xhr.send(selectedFile);
        }
    };

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    return (
        <div>

            <div {...getRootProps()} style={{ border: "2px solid #ccc", padding: "5vw", textAlign: "center" }}>
                <input {...getInputProps()} />
                <p className="file">Arrastra y suelta archivos aquí o haz clic para seleccionar archivos</p>
                {/*
            <button onClick={handleUpload} className="file">Subir Archivo</button>
            */}
                <button onClick={handleUpload} style={{ fontSize: "3vw" }} >
                    <i className="fa fa-cloud-upload"></i>
                </button>

                {/* Barra de progreso */}
                <progress value={uploadProgress} max="100"></progress>
            </div>

            <div>
                <CameraControls videoRef={videoRef} updateFileList={updateFileList} />
            </div>
        </div>
    );
};

export default FileUploader;
