// FileManager.js
import React, { useState, useEffect } from "react";
import AWS from "./aws-config";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from "@mui/material/TextField";
//import { color } from "framer-motion";
import ClearIcon from '@mui/icons-material/Clear';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
//import { css } from '@emotion/react';

const FileManager = ({ fileList, onDeleteFile, updateFileList }) => {
    const [selectedFile, setSelectedFile] = useState(""); // Agregar un estado para el archivo seleccionado
    const [searchQuery] = useState(""); // Estado para almacenar la consulta de búsqueda
    const [newFileName, setNewFileName] = useState(""); // Nuevo estado para el nombre del archivo
    const [errorMessage, setErrorMessage] = useState(""); // Agrega un estado para el mensaje de error
    const [deleteConfirmation, setDeleteConfirmation] = useState(false); // Nuevo estado para la confirmación
    const [deleteTimeout, setDeleteTimeout] = useState(null); // Nuevo estado para gestionar el tiempo de confirmación
    const [remainingTime, setRemainingTime] = useState(5);
    const [buttonsDisabled, setButtonsDisabled] = useState(true);
    const [selectedValue, setSelectedValue] = useState(null);
    const [fontSizeFile, setFontSizeFile] = useState('inherit');


    const handleSelectionChange = (event, value) => {
        if (value === null) {
            setNewFileName("");
            setSelectedValue(null);
            console.log("valor nulo");
        } else {
            setSelectedValue(value);
            handleFileSelection(event, value);
        }

        console.log("Valor seleccionado:", value);
        console.log("Etiqueta seleccionada:", value ? value.label : null);
    };

    // Filtra la lista de archivos según la consulta de búsqueda
    const filteredFiles = fileList.filter((fileName) =>
        fileName.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleFileSelection = (e, value) => {
        setSelectedFile(value || ""); // Actualiza el archivo seleccionado en el estado
        setNewFileName(value); // También establecemos el nuevo nombre como el nombre actual
        setButtonsDisabled(!value); // Comprueba si se ha seleccionado un archivo y actualiza la habilitación de los botones.
    };

    const handleDownloadFile = () => {
        if (selectedFile) {
            const s3 = new AWS.S3();
            const params = {
                Bucket: "emmanueltc.db.tools",
                Key: selectedFile,
            };

            s3.getObject(params, (err, data) => {
                if (err) {
                    console.error(err);
                    if (err.code === 'ETagMissing') {
                        console.error("Error: No se pudo acceder al encabezado ETag.");
                    }
                } else {
                    // Crear un enlace para la descarga
                    const blob = new Blob([data.Body], { type: data.ContentType });
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.href = url;
                    a.download = selectedFile;

                    // Hacer clic en el enlace para iniciar la descarga
                    a.click();

                    // Liberar el objeto URL
                    window.URL.revokeObjectURL(url);
                }
            });
        }
    };

    const handleRenameFile = () => {
        if (selectedFile && newFileName) {
            if (selectedFile === newFileName) {
                // Muestra un mensaje temporal si el nombre es el mismo
                setErrorMessage("El nombre a cambiar debe ser diferente");
                setTimeout(() => {
                    setErrorMessage("");
                }, 2000); // Borra el mensaje después de 2 segundos
            } else {
                // Lógica para cambiar el nombre del archivo
                const s3 = new AWS.S3();
                const params = {
                    Bucket: "emmanueltc.db.tools",
                    CopySource: `/${"emmanueltc.db.tools"}/${selectedFile}`,
                    Key: newFileName,
                };

                s3.copyObject(params, (copyErr, copyData) => {
                    if (copyErr) {
                        console.error(copyErr);
                    } else {
                        console.log("Archivo copiado con éxito:", newFileName);
                        // Ahora, elimina el archivo original
                        const deleteParams = {
                            Bucket: "emmanueltc.db.tools",
                            Key: selectedFile,
                        };

                        s3.deleteObject(deleteParams, (deleteErr, deleteData) => {
                            if (deleteErr) {
                                console.error(deleteErr);
                            } else {
                                console.log("Archivo eliminado con éxito:", selectedFile);
                                updateFileList(); // Actualiza la lista de archivos
                                setSelectedFile(""); // Limpia el archivo seleccionado
                                //setNewFileName(""); // Limpia el nuevo nombre
                                setSelectedValue(null);

                            }
                        });
                    }
                });
            }
        }
    };

    const handleDeleteFile = () => {
        if (deleteConfirmation) {
            const s3 = new AWS.S3();
            const deleteParams = {
                Bucket: "emmanueltc.db.tools",
                Key: selectedFile,
            };

            s3.deleteObject(deleteParams, (deleteErr, deleteData) => {
                if (deleteErr) {
                    console.error(deleteErr);
                } else {
                    console.log("Archivo eliminado con éxito:", selectedFile);
                    updateFileList();
                    setSelectedFile("");
                    setNewFileName("");
                    setSelectedValue(null);
                    setDeleteConfirmation(false);
                    setRemainingTime(5); // Restablecer el tiempo
                    clearTimeout(deleteTimeout);
                    setButtonsDisabled(true); // Deshabilitar los botones después de la eliminación
                }
            });
        } else {
            setDeleteConfirmation(true);
            setDeleteTimeout(
                setTimeout(() => {
                    setDeleteConfirmation(false);
                    setRemainingTime(5); // Restablecer el tiempo
                }, 5000)
            );
        }
    };

    const handleUpdateList = () => {
        // Llama a la función updateFileList para actualizar la lista
        updateFileList();
        console.log("Jala");
    };


    useEffect(() => {
        if (deleteConfirmation && remainingTime > 0) {
            const timer = setInterval(() => {
                setRemainingTime((prevTime) => prevTime - 1);
            }, 1000);

            return () => {
                clearInterval(timer);
            };
        }

        // Limpiar el nuevo nombre cuando se deselecciona un archivo
        if (!selectedValue) {
            //setNewFileName("");
            setSelectedValue(null);
        }
    }, [deleteConfirmation, remainingTime, selectedValue]);


    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            let newFontSize = '10vw';

            if (width >= 1921) {
                newFontSize = '2.2vw';
            } else if (width >= 1281) {
                newFontSize = '2.6vw';
            } else if (width >= 961) {
                newFontSize = '3.0vw';
            } else if (width >= 601) {
                newFontSize = '3.6vw';
            } else if (width <= 600) {
                newFontSize = '3.5vw';
            }

            // Solo actualizamos el estado si el tamaño de fuente cambia
            if (newFontSize !== fontSizeFile) {
                setFontSizeFile(newFontSize);
            }
        };

        // Agregar el event listener al montar el componente
        window.addEventListener('load', handleResize);
        window.addEventListener('resize', handleResize);

        // Limpieza del event listener al desmontar el componente
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [fontSizeFile]); // Ahora dependemos de fontSize para evitar actualizaciones innecesarias


    return (
        <div className="file-downloader">

            <div className="file-actions" style={{ textAlign: "-webkit-center" }}>

                {/* Ver si hago lo de todos y busqueda */}
                <div>
                    {/*
                    
                    <input
                        type="text"
                        value={searchQuery}
                        onChange={handleSearch}
                        placeholder="Buscar archivos"
                    />
                    <select onChange={handleFileSelection}>
                        <option value="">Selecciona un archivo</option>
                        <optgroup label="Resultados de búsqueda">
                            {filteredFiles.map((fileName) => (
                                <option key={fileName} value={fileName}>
                                    {fileName}
                                </option>
                            ))}
                        </optgroup>
                        <optgroup label="Todos los archivos">
                            {fileList.map((fileName) => (
                                <option key={fileName} value={fileName}>
                                    {fileName}
                                </option>
                            ))}
                        </optgroup>
                    </select>
                            */}
                </div>

                {/*
                <div style={{ fontSize: fontSizeFile }}>
                    ababababab
                </div>
                */}

                <div className="GutterX0 row" style={{ display: "flex", width: "95%" }}>
                    <div className="col-9" style={{ display: "flex", paddingRight: "5%" }}>
                        <button className="col-1" onClick={handleUpdateList} style={{ float: "left", fontSize: "3vw" }}> &#x21BB; </button>

                        <Autocomplete className="col-6 prueba"
                            options={filteredFiles}
                            clearIcon={<ClearIcon style={{ color: "var(--text-primary)", fontSize: "3vw" }} />}
                            popupIcon={<ArrowDropDownIcon style={{ color: "var(--text-primary)", fontSize: "3vw" }} />}
                            value={selectedValue}
                            onChange={handleSelectionChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Seleccionar"
                                    variant="outlined"
                                    onFocus={handleUpdateList}
                                    InputLabelProps={{
                                        style: {//Adentro
                                            fontSize: fontSizeFile,
                                            color: 'orange',
                                            borderColor: 'var(--text-primary)',
                                            borderStyle: "solid",
                                            borderWidth: "0px 0px 0px 0px",
                                            //borderColor: "#ffffff50 #ffffff50 var(--text-primary) #ffffff50"
                                        }
                                    }}
                                    sx={{//Medio
                                        '& .MuiOutlinedInput-input': {
                                            borderColor: 'green', // Cambia el color del borde a rojo
                                            borderWidth: "0px",
                                            height:"auto"
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: 'var(--text-primary)',
                                            borderColor: '#ffffff50 #ffffff50 var(--text-primary) #ffffff50',
                                            borderStyle: 'solid',
                                            borderWidth: '0px 0px 0px 0px',
                                        },
                                        '&.MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: '#ffffff50 #ffffff50 var(--text-primary) #ffffff50',
                                                borderStyle: 'solid',
                                                borderWidth: '1px',
                                            },
                                        },
                                    }}
                                    InputProps={{
                                        ...params.InputProps,
                                        style: {//Afuera
                                            fontSize: fontSizeFile,
                                            paddingTop:"20px",
                                            height:"100%",
                                            color: "var(--text-primary)",
                                            borderStyle: "solid",
                                            backgroundColor: "var(--background)",
                                            borderWidth: "1px",
                                            borderColor: "#ffffff50 #ffffff50 var(--text-primary) #ffffff50"
                                        }
                                    }}
                                />
                            )}
                        />

                        <TextField
                            className="col-5"
                            type="text"
                            value={newFileName}
                            onChange={(e) => setNewFileName(e.target.value)}
                            label="Renombrar"
                            variant="filled"
                            sx={{//Medio
                                '& .MuiFilledInput-input': {
                                    borderColor: 'green', // Cambia el color del borde a rojo
                                    borderWidth: "0px",
                                    height:"100%",
                                    padding:"15px"
                                }
                            }}
                            InputProps={{ style: { paddingTop:"20px", fontSize: fontSizeFile, color: "var(--text-primary)", borderStyle: "solid", backgroundColor: "var(--background)", borderWidth: "1px", borderColor: "#ffffff50 #ffffff50 var(--text-primary) #ffffff50" } }}
                            InputLabelProps={{ style: { fontSize: fontSizeFile, color: 'orange', borderColor: 'var(--text-primary)' } }}
                        />
                    </div>

                    <div className="col-3" style={{ display: "flex", paddingLeft: "5%" }}>
                        <button className="col-4" onClick={handleRenameFile} title="Cambiar Nombre" style={{ opacity: buttonsDisabled ? "0.3" : "1", fontSize: "3vw" }} disabled={buttonsDisabled}>
                            <i className="fa fa-pencil"></i>
                        </button>

                        <button className="col-4" onClick={handleDownloadFile} title="Descargar Archivo" style={{ opacity: buttonsDisabled ? "0.3" : "1", fontSize: "3vw" }} disabled={buttonsDisabled}>
                            <i className="fa fa-download"></i>
                        </button>


                        {/* 
                            <button onClick={() => onDeleteFile(selectedFile)} title="Borrar Archivo" style={{ margin: "0px 10px" }}>
                                <i className="fa fa-trash"></i>
                            </button>                
                        */}

                        <button
                            onClick={handleDeleteFile}
                            title="Borrar Archivo"
                            className={`col-4 ${deleteConfirmation ? "confirm-delete" : ""}`}
                            style={{ opacity: buttonsDisabled ? "0.3" : "1", fontSize: "3vw" }}
                            disabled={buttonsDisabled}
                        >
                            <i className={`fa fa-trash${deleteConfirmation ? " deleting" : ""}`}></i>
                            {deleteConfirmation && (
                                <span className="remaining-time">Tiempo restante: {remainingTime} s</span>
                            )}
                        </button>
                    </div>

                </div>

                {errorMessage && <div className="error-message" style={{ color: "orange" }}>{errorMessage}</div>}
                {deleteConfirmation && (
                    <div className="confirm-message" style={{ color: "red", textAlign: "right"/*, float:"right"*/ }}>
                        Confirmar la eliminación
                    </div>
                )}

            </div>
        </div>
    );
};

export default FileManager;