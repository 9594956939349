import React, { useState, useEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import { AnimatePresence } from 'framer-motion';
import { motion } from 'framer-motion';


import IconButton from '@mui/material/IconButton';
//import SvgIcon from '@mui/material/SvgIcon';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
/*import PersonOffIcon from '@mui/icons-material/PersonOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';*/

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import TuneIcon from '@mui/icons-material/Tune';

const TwitchChannelList = () => {
  const [channels, setChannels] = useState([]);
  const [newChannel, setNewChannel] = useState('');
  const [showChats, setShowChats] = useState(true);
  const [showStreams, setShowStreams] = useState(true);

  const [open, setOpen] = useState(false);

  const [activeChannel, setActiveChannel] = useState('');

  const HideShowHeader = () => {
    const Header = document.getElementById("Header");
    //    const HeaderIndex = document.getElementById("toolbar");
    Header.style.display = (Header.style.display === "none") ? "flex" : "none";
    //    HeaderIndex.style.height = (HeaderIndex.style.height === "0vh") ? "4.5vh" : "0vh"

    const HeaderBoton = document.getElementById("HeaderBoton");
    HeaderBoton.innerHTML = (HeaderBoton.innerHTML === "Mostrar Header") ? "Esconder Header" : "Mostrar Header";

    const HeaderContent = document.getElementById("IDHeaderContent");
    HeaderContent.style.paddingTop = (HeaderContent.style.paddingTop === "7.5vh") ? "0vh" : "7.5vh";
  };

  const HideShowInput = () => {
    const Input = document.getElementById("Input");
    Input.style.display = (Input.style.display === "none") ? "flex" : "none";

    const InputBoton = document.getElementById("InputBoton");
    InputBoton.innerHTML = (InputBoton.innerHTML === "Esconder Input") ? "Mostrar Input" : "Esconder Input";
  };

  const HideShowAvatar = () => {
    const Avatares = document.getElementById("Avatares");
    Avatares.style.display = (Avatares.style.display === "none") ? "flex" : "none";

    const AvataresBoton = document.getElementById("AvataresBoton");
    AvataresBoton.innerHTML = (AvataresBoton.innerHTML === "Esconder Avatares") ? "Mostrar Avatares" : "Esconder Avatares";
  };

  const handleConfOpen = () => {
    setOpen(true);
    EstadoHeader();
    EstadoInput();
    EstadoAvatares();
  };

  const EstadoHeader = () => {
    setTimeout(() => {
      const Header = document.getElementById("Header");
      const HeaderBoton = document.getElementById("HeaderBoton");
      HeaderBoton.innerHTML = (Header.style.display === "none") ? "Mostrar Header" : "Esconder Header";
    }, 0);
  };

  const EstadoInput = () => {
    setTimeout(() => {
      const Input = document.getElementById("Input");
      const InputBoton = document.getElementById("InputBoton");
      InputBoton.innerHTML = (Input.style.display === "none") ? "Mostrar Input" : "Esconder Input";
    }, 0);
  };

  const EstadoAvatares = () => {
    setTimeout(() => {
      const Avatares = document.getElementById("Avatares");
      const AvataresBoton = document.getElementById("AvataresBoton");
      AvataresBoton.innerHTML = (Avatares.style.display === "none") ? "Mostrar Avatares" : "Esconder Avatares";
    }, 0);
  };

  const handleConfClose = () => {
    setOpen(false);
  };

  // eslint-disable-next-line
  const [activeLink, setActiveLink] = useState('/');

  const handleMouseEnter = (id) => {
    if (activeLink !== id) {
      const element = document.getElementById(id);
      if (element) {
        element.style.backgroundColor = '#7932BC30';
        element.style.color = 'white';
      }
    }
  };

  const handleMouseLeave = (id) => {
    if (activeLink !== id) {
      const element = document.getElementById(id);
      if (element) {
        element.style.backgroundColor = 'transparent';
        element.style.color = '#999999';
        if (element.classList.contains('active')) {
          element.style.backgroundColor = 'transparent';
          element.style.color = 'white';
        }
      }
    }
  };

  useEffect(() => {
    const inputCanal = document.getElementById("inputCanal");
    const instruccion = document.getElementById("Instruccion");

    const handleFocus = () => {
      instruccion.style.display = "flex";
    };

    const handleFocusOut = () => {
      instruccion.style.display = "none";
    };

    inputCanal.addEventListener("focus", handleFocus);
    inputCanal.addEventListener("focusout", handleFocusOut);

    // Obtener información de los canales al cargar el componente
    fetchChannelInfo();

    // Cleanup: Remover event listeners al desmontar el componente
    return () => {
      inputCanal.removeEventListener("focus", handleFocus);
      inputCanal.removeEventListener("focusout", handleFocusOut);
    };
    // eslint-disable-next-line
  }, []);

  const fetchChannelInfo = async () => {
    const promises = channels.map(async (channel) => {
      const response = await fetch(`https://api.twitch.tv/helix/users?login=${channel}`, {
        headers: {
          'Client-ID': '3788ix1ddntbwwuzzz1tvhzktk4nti', // Reemplazar con tu Client ID de Twitch
        },
      });
      const data = await response.json();
      return {
        name: channel,
        avatar: data?.data[0]?.profile_image_url || '', // Obtener el avatar del primer resultado (si existe)
      };
    });

    const channelInfo = await Promise.all(promises);

    setChannels(channelInfo);
  };

  const handleAddChannel = async () => {
    if (newChannel.trim() !== '') {
      try {
        const authResponse = await fetch('https://id.twitch.tv/oauth2/token', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            client_id: '3788ix1ddntbwwuzzz1tvhzktk4nti', // Reemplazar con tu Client ID de Twitch
            client_secret: 'd4xqkrobl0119znbm112t723b7hp3b', // Reemplazar con tu Secret ID de Twitch
            grant_type: 'client_credentials',
          }),
        });

        if (authResponse.ok) {
          const authData = await authResponse.json();
          const accessToken = authData.access_token;

          const channelResponse = await fetch(`https://api.twitch.tv/helix/users?login=${newChannel}`, {
            headers: {
              'Client-ID': '3788ix1ddntbwwuzzz1tvhzktk4nti', // Reemplazar con tu Client ID de Twitch
              Authorization: `Bearer ${accessToken}`,
            },
          });

          if (channelResponse.ok) {
            const channelData = await channelResponse.json();
            if (channelData.data.length > 0) {
              const newChannelInfo = {
                name: newChannel,
                avatar: channelData.data[0]?.profile_image_url || '',
              };

              setChannels((prevChannels) => [...prevChannels, newChannelInfo]);
              setNewChannel('');
            } else {
              alert('No se encontró el canal de Twitch');
            }
          } else {
            alert('Error al obtener información del canal de Twitch');
          }
        } else {
          alert('Error al autenticar con Twitch');
        }
      } catch (error) {
        console.log('Error:', error);
        alert('Error al agregar el canal');
      }
    }
  };

  const handleRemoveChannel = (channel) => {
    setChannels((prevChannels) => prevChannels.filter((c) => c.name !== channel.name));
  };

  const handleToggleChats = () => {
    setShowChats((prevShowChats) => !prevShowChats);
  };

  const calculateGridSize = (numStreams) => {
    if (numStreams === 1) {
      return '100';
    } else if (numStreams === 2) {
      return '50';
    } else if (numStreams >= 3 && numStreams <= 4) {
      return '50';
    } else if (numStreams >= 5 && numStreams <= 6
    ) {
      return '33.3';
    } else if (numStreams >= 7 && numStreams <= 9) {
      return '33.3';
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      // 👇 Get input value
      handleAddChannel();
    }
  };

  const handleToggleStreams = () => {
    setShowStreams(!showStreams);
  };

  const handleChannelTabClick = (channel) => {
    setActiveChannel(channel.name);
  };

  return (
    <div style={{ backgroundColor: "#101010" }}>



      <div id='InputAvatar'>{/* Input y Avatares*/}
        <div style={{ position: "fixed", left: "28.5vw", zIndex: 100001 }}>{/* Configuración */}
          <IconButton aria-label="Example" onClick={handleConfOpen}>
            {/*<SvgIcon>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="#ffffff"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4.5 12a7.5 7.5 0 0015 0m-15 0a7.5 7.5 0 1115 0m-15 0H3m16.5 0H21m-1.5 0H12m-8.457 3.077l1.41-.513m14.095-5.13l1.41-.513M5.106 17.785l1.15-.964m11.49-9.642l1.149-.964M7.501 19.795l.75-1.3m7.5-12.99l.75-1.3m-6.063 16.658l.26-1.477m2.605-14.772l.26-1.477m0 17.726l-.26-1.477M10.698 4.614l-.26-1.477M16.5 19.794l-.75-1.299M7.5 4.205L12 12m6.894 5.785l-1.149-.964M6.256 7.178l-1.15-.964m15.352 8.864l-1.41-.513M4.954 9.435l-1.41-.514M12.002 12l-3.75 6.495"
                  />
                </svg>
              </SvgIcon>*/}

            <TuneIcon sx={{ fontSize: { xs: "4vw", md: "4vw", lg: "3vw" }, color: "var(--text-primary)" }} />

          </IconButton>
        </div>
        <div id='Input' style={{ display: "flex", justifyContent: "center", paddingTop: "10px" }}>{/* Input */}

          <input
            id='inputCanal'
            type="text"
            value={newChannel}
            onChange={(e) => setNewChannel(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Escribe el canal"
            style={{ textAlign: "center" }}
          />
          <IconButton onClick={handleAddChannel} sx={{ backgroundColor: "#666666", marginTop: "-7px" }}>
            <PersonAddIcon sx={{ fontSize: { xs: "4vw", md: "3vw", lg: "2vw" }, color: "var(--text-primary)" }} />
          </IconButton>
        </div>
        <div id='Instruccion' style={{ display: "none", color: "#ffffff", justifyContent: "center" }}> Preciona <div style={{ color: "#00ff00", marginLeft: "5px", marginRight: "5px" }}>Enter</div> para cargar el canal</div>

        <div id='Avatares' style={{ display: "flex", justifyContent: "center" }}>
          {channels.map((channel) => (/* Avatares */
            <div key={channel.name} >
              <Tooltip
                title={
                  <div>
                    <h5 style={{ display: "flex", justifyContent: "center" }}>{channel.name}</h5>
                    <img
                      src={channel.avatar}
                      alt={channel.name}
                      width="100%"
                      height="100%"
                      style={{ cursor: 'pointer' }}
                    />
                  </div>
                } placement="bottom" TransitionComponent={Zoom}
              >
                <img
                  src={channel.avatar}
                  alt="Avatar"
                  width="30"
                  height="30"
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleRemoveChannel(channel)}
                  onMouseEnter={(e) => {
                    e.target.nextSibling.style.display = 'block';
                  }}
                  onMouseLeave={(e) => {
                    e.target.nextSibling.style.display = 'none';
                  }}
                />
              </Tooltip>
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: '100%',
                  display: 'none',
                  backgroundColor: 'rgba(0, 0, 0, 0.8)',
                  color: '#fff',
                  padding: '5px',
                  borderRadius: '4px',
                }}
              >
                {channel.name}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="row" style={{ margin: 0 }}> {/* Stream y Chat */}
        {showStreams && (
          <div className={showChats ? 'col-8' : 'col-12'}>

            <div
              className="stream-container"
              style={{ height: `${80}vh`, width: `${100}%`, float: "left" }}
            >
              {channels.map((channel, index) => (
                <iframe
                  key={channel.name}
                  src={`https://player.twitch.tv/?channel=${channel.name}&parent=${window.location.hostname}`}
                  height={`${calculateGridSize(channels.length)}%`}
                  width={`${calculateGridSize(channels.length)}%`}
                  title={channel.name}
                  style={{ marginBottom: "-6px" }}
                  allowFullScreen={true}
                ></iframe>
              ))}
            </div>

          </div>
        )}
        {showChats && (
          <div className={showStreams ? 'col-4' : 'col-12'}>

            <Tabs
              value={activeChannel}
              onChange={(event, value) => handleChannelTabClick(value)}
              variant="scrollable"
              scrollButtons="auto"
              TabScrollButtonProps={{ style: { background: '', color: "#7932BC" } }}
              TabIndicatorProps={{ style: { background: '#7932BC' } }}
              textColor="secondary"
              sx={{ color: "white", background: "#292929", paddingRight: "1.4vw", borderColor: "#7932BC", borderStyle: "solid", borderWidth: "0px", borderTopWidth: "2px" }}
            >
              {channels.map((channel) => (
                <Tab
                  key={channel.name}
                  value={channel.name}
                  label={channel.name}
                  sx={{ color: "white", borderColor: "#7932BC" }}
                  className={`chat-tab ${activeChannel === channel.name ? 'active' : ''}`}
                  onClick={() => handleChannelTabClick(channel)}
                  onMouseEnter={() => handleMouseEnter(channel.name)}
                  onMouseLeave={() => handleMouseLeave(channel.name)}
                />
              ))}
            </Tabs>

            {channels.map((channel) => {
              if (activeChannel === channel.name) {
                return (
                  <div
                    className="chat-container"
                    style={{ height: "100vh"/*`${100 / channels.length}vh`*/ }}
                  >
                    <iframe
                      key={`${channel.name}-chat`}
                      src={`https://www.twitch.tv/embed/${channel.name}/chat?parent=${window.location.hostname}`}
                      width="100%"
                      height="100%"
                      title={`${channel.name}-chat`}
                      allowFullScreen={true}
                    ></iframe>
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
        )}
      </div>

      <AnimatePresence>
        {open && (
          <motion.div>
            <motion.div
              onClick={handleConfClose}
              initial={{ opacity: "0%" }}
              animate={{ opacity: "100%" }}
              exit={{ opacity: '0%' }}
              style={{
                position: "fixed",
                width: "100vw",
                height: "100vh",
                backgroundColor: "#00000099",
                backdropFilter: "blur(3px)",
                top: "0vh",
                left: "0vw",
                zIndex: 999
              }}
            >

            </motion.div>
            <motion.div
              key="drawer"
              initial={{ x: '100%' }}
              animate={{ x: 0 }}
              exit={{ x: '100%' }}
              style={{
                position: 'fixed',
                top: '5%',
                right: 0,
                width: '40%',
                height: '95%',
                backgroundColor: '#111111',
                zIndex: 999,
                padding: '2rem',
              }}
            >
              <button onClick={handleConfClose} >Cerrar</button>

              <div style={{ display: 'grid', backgroundColor: "#22222240" }}> {/** Movil */}
                <h1 style={{ color: "red" }}>Visibilidad</h1>
                <div className='row'>
                  <div className='col-4 p-1'>
                    <button id="HeaderBoton" className='btn btn-primary' onClick={HideShowHeader}>Esconder Header</button>
                  </div>
                  <div className='col-4 p-1'>
                    <button id="InputBoton" className='btn btn-primary' onClick={HideShowInput}>Esconder Input</button>
                  </div>
                  <div className='col-4 p-1'>
                    <button id="AvataresBoton" className='btn btn-primary' onClick={HideShowAvatar}>Esconder Avatares</button>
                  </div>
                  <div className='col-4 p-1'>
                    <button className='btn btn-primary' onClick={handleToggleChats}>{showChats ? 'Hide Chats' : 'Show Chats'}</button>
                  </div>
                  <div className='col-4 p-1'>
                    <button className='btn btn-primary' onClick={handleToggleStreams}>{showStreams ? 'Hide Streams' : 'Show Streams'}</button>
                  </div>
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>



    </div>
  );
};

export default TwitchChannelList;